import { Grid, Stack, Typography, TypographySystem } from '@mui/joy';

import ComparisonGraph from './ComparisonGraph';

const RiskAreaComparison = ({ data, showMarkPoint }) => {
  return (
    <Grid container gridColumn={3} width={1} spacing={4}>
      {data.map((risk, index) => (
        <>
          <Grid xs={4} sx={{ alignContent: 'center' }}>
            <Stack
              gap={1.2}
              sx={{
                width: 1,
                height: 1,
                borderRight: (theme) =>
                  // border should be added between avery element except the last one there are 3 elements in a row
                  index !== data.length - 1 && index % 3 !== 2
                    ? `1px solid ${theme.palette['divider']}`
                    : '',
              }}
            >
              <Typography
                level={risk.safeguardAreas ? ('sub1' as keyof TypographySystem) : 'title-md'}
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {risk.safeguardAreas ? `${risk.displayName} risks` : risk.displayName}
              </Typography>
              {risk.safeguardAreas && (
                <>
                  <Typography
                    level='body-sm'
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  >
                    Includes risks identified in these safeguard areas:
                  </Typography>
                  <Stack sx={{ listStyleType: 'disc', pl: 2 }}>
                    {(risk.safeguardAreas || []).map((area) => (
                      <Typography
                        level='body-sm'
                        sx={{
                          display: 'list-item',
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        {area}
                      </Typography>
                    ))}
                  </Stack>
                </>
              )}
              <ComparisonGraph
                data={risk}
                showMarkPoint={showMarkPoint}
                align={(index + 1) % 3 === 0 ? 'left' : 'right'}
              />
            </Stack>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

export default RiskAreaComparison;
