import { useMemo } from 'react';

import { Typography, useTheme } from '@mui/joy';

import { transformRiskData } from '../../../constants';
import { StyledHTML } from '../StyledHTML';

import ImageAndDescription from './ImageAndDescription';
import RiskInfo from './RiskInfo';
import RiskSummaryTable from './RiskSummaryTable';

const DetailedRiskFindings: React.FC<{
  data: any;
  scrollReadMoreIntoView: (param: React.RefObject<HTMLDivElement> | string) => void;
}> = ({ data, scrollReadMoreIntoView }) => {
  const risks = useMemo(() => transformRiskData(data.risk_data), [data]);
  const theme = useTheme();

  return (
    <>
      <StyledHTML
        fontWeight={'md'}
        fontSize={theme.vars.fontSize.lg}
        color='primary'
        dangerouslySetInnerHTML={{ __html: data.detailed_risk_summary }}
      />
      <Typography level='h4' color='primary'>
        Summary of identified risks related to carbon project activities
      </Typography>
      <RiskSummaryTable data={risks} scrollReadMoreIntoView={scrollReadMoreIntoView} />
      {data.image_url && (
        <ImageAndDescription image={data.image_url} description={data.image_description} />
      )}
      {risks.map((risk) => (
        <RiskInfo key={risk.name} data={risk} />
      ))}
    </>
  );
};

export default DetailedRiskFindings;
