import React, { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  Card,
  List,
  Stack,
  Typography,
} from '@mui/joy';
import { FiMinus } from 'react-icons/fi';
import { IoMdAdd } from 'react-icons/io';

import { track } from '../../services/analytics';
import {
  EVENT_PROGRAM_SCREENING_PAGE,
  EVNT_program_approach_clicked,
} from '../../services/analytics/events';

import { StyledAccordionSummary, StyledListItem } from './styles';

const Approach: React.FC = () => {
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    if (expanded[1]) {
      track(EVENT_PROGRAM_SCREENING_PAGE, EVNT_program_approach_clicked, {
        name: 'Areas of assessment',
      });
    } else if (expanded[2]) {
      track(EVENT_PROGRAM_SCREENING_PAGE, EVNT_program_approach_clicked, {
        name: 'Minimum criteria to pass',
      });
    }
  }, [expanded]);
  return (
    <Card
      sx={{
        p: 3,
        background: (theme) => theme.palette.background.surface,
        border: (theme) => `1px solid ${theme.palette['border']['border']}`,
      }}
    >
      <Stack gap={3}>
        <Typography level='h4' color='primary'>
          Approach
        </Typography>
        <Typography level='body-md' color='primary'>
          Calyx Global’s Integrity Framework involves a three-step approach to carbon credit ratings
          – the first step is to screen the programs issuing credits. We believe organizations that
          create tradable carbon assets should exhibit a minimum level of governance, transparency,
          and operational features. To this end, Calyx Global has developed a comprehensive
          screening tool, approved by our GHG Integrity Panel, that we apply to all carbon crediting
          programs. This tool consists of 31 criteria covering six areas of assessment.
        </Typography>
        <AccordionGroup>
          <Accordion
            expanded={expanded[1]}
            onChange={() => setExpanded((prev) => ({ ...prev, 1: !prev[1] }))}
          >
            <StyledAccordionSummary
              active={expanded[1]}
              sx={{ fontSize: 'xl', fontWeight: 'xl' }}
              indicator={expanded[1] ? <FiMinus /> : <IoMdAdd />}
            >
              Areas of assessment
            </StyledAccordionSummary>
            <AccordionDetails
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontSize: (theme) => theme.vars.fontSize.md,
                fontWeight: (theme) => theme.vars.fontWeight.md,
                pb: expanded[1] ? 2 : 0,
              }}
            >
              Our screening covers the following areas of assessment:
              <List
                sx={{
                  listStyleType: 'disc',
                  pl: 6,
                }}
              >
                <StyledListItem>
                  Governance: The administration and governance of the program, issues related to
                  conflicts of interest, procedures to manage complaints and grievances, and whether
                  the program defines and ensures the legal attributes and property aspects of
                  credited units.
                </StyledListItem>
                <StyledListItem>
                  Rules and procedures: Whether the program’s governance structure and regulatory
                  documentation are publicly available, and how methodologies are developed and
                  approved.
                </StyledListItem>
                <StyledListItem>
                  Stakeholder engagement: The extent to which public comment is required and the
                  requirements for the program to respond.
                </StyledListItem>
                <StyledListItem>
                  Transparency: The level of public information required on credited projects, such
                  as project design and implementation, how emission reductions or removals were
                  calculated, sustainable development goal (SDG) contributions and any auditing of
                  the project or its claims.
                </StyledListItem>
                <StyledListItem>
                  Validation and verification: Whether both are required by an independent,
                  accredited third party, the procedures and guidance provided to such parties, and
                  oversight to ensure robust and consistent practice.
                </StyledListItem>
                <StyledListItem>
                  Registry: This includes secure registry operations, public information showing
                  issuance, cancellation, and retirement of units (including parties who have
                  retired them), and procedures to avoid overlapping claims.
                </StyledListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded[2]}
            onChange={() => setExpanded((prev) => ({ ...prev, 2: !prev[2] }))}
          >
            <StyledAccordionSummary
              active={expanded[2]}
              sx={{ fontSize: 'xl', fontWeight: 'xl' }}
              indicator={expanded[2] ? <FiMinus /> : <IoMdAdd />}
            >
              Minimum criteria to pass
            </StyledAccordionSummary>
            <AccordionDetails
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontSize: (theme) => theme.vars.fontSize.md,
                fontWeight: (theme) => theme.vars.fontWeight.md,
                pb: expanded[2] ? 2 : 0,
              }}
            >
              While we assess 31 criteria when screening programs, there are six important criteria
              that must be met in order for the program to pass (and in order for us to rate
              specific credits issued by the program):
              <List
                sx={{
                  listStyleType: 'disc',
                  pl: 6,
                }}
              >
                <StyledListItem>
                  Maintaining a publicly-available registry that uniquely identifies projects and
                  transparently issues carbon credits
                </StyledListItem>
                <StyledListItem>
                  Publicly providing the program’s regulatory documents, including methodologies
                </StyledListItem>
                <StyledListItem>
                  Requiring stakeholder consultations on individual projects
                </StyledListItem>
                <StyledListItem>
                  Requiring validation and verification from an accredited, third-party entity prior
                  to issuance of credits
                </StyledListItem>
                <StyledListItem>
                  Utilizing a system that securely issues, transfers, retires, and cancels carbon
                  credits
                </StyledListItem>
                <StyledListItem>
                  Having procedures in place to protect against double counting
                </StyledListItem>
              </List>
              The remaining 25 criteria in our screening are considered “best practice” and the
              degree to which programs meet these criteria result in a scoring and categorization,
              as seen in the table above: Gold, Silver or Bronze.
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      </Stack>
    </Card>
  );
};

export { Approach };
