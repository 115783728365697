import { Button, Grid, Stack, Typography } from '@mui/joy';
import { TbBulb } from 'react-icons/tb';

import { HTMLRenderer } from './HTMLRenderer';
import { RatingSemicircle } from './RatingSemicircle';

interface PropTypes {
  titleText?: string;
  textContent?: string;
  selectedTab?: string;
  sectionRatings?:
    | {
        additionality: number;
        permanence: number;
        overlapping_claims: number;
        over_crediting: number;
      }
    | undefined;
  onlyShowSectionRatings?: boolean;
  isReadMoreDataAvailable?: boolean;
  scrollReadMoreIntoView?: () => void;
  isNoDetailedData?: boolean;
}

export const Paragraph = ({
  textContent,
  sectionRatings,
  selectedTab,
  titleText,
  onlyShowSectionRatings = false,
  isReadMoreDataAvailable = false,
  scrollReadMoreIntoView,
  isNoDetailedData = false,
}: PropTypes) => {
  const isOverview = selectedTab === 'overview';

  return (
    <Grid container justifyContent={'space-between'} rowGap={3}>
      {onlyShowSectionRatings ? null : (
        <Grid
          xs={isOverview ? 12 : !!sectionRatings ? 8 : 12}
          lg={isOverview ? 6.5 : !!sectionRatings ? 9 : 12}
          spacing={1}
        >
          {!!titleText ? (
            <Typography fontSize={'xl2'} fontWeight={'xl'} color='primary' mb={3}>
              {titleText}
            </Typography>
          ) : null}
          <Typography level='body-md' color='neutral'>
            <HTMLRenderer
              colorType='secondary'
              dangerouslySetInnerHTML={{ __html: `${textContent ?? ''}` }}
            />
          </Typography>
        </Grid>
      )}
      {!!sectionRatings ? (
        <Grid
          xs={isNoDetailedData ? 12 : isOverview ? 12 : 3.4}
          lg={isNoDetailedData ? 12 : isOverview ? 4.5 : 2.4}
        >
          {isOverview || onlyShowSectionRatings ? (
            <Grid container justifyContent={'space-between'} gap={2}>
              <Grid xs={2} width={'160px'}>
                <RatingSemicircle
                  rating={Number(sectionRatings?.additionality)}
                  title={'Additionality'}
                />
              </Grid>
              <Grid xs={2} width={'160px'}>
                <RatingSemicircle
                  rating={Number(sectionRatings?.over_crediting)}
                  title={'Over-crediting'}
                />
              </Grid>
              <Grid xs={2} width={'160px'}>
                <RatingSemicircle
                  rating={Number(sectionRatings?.permanence)}
                  title={'Permanence'}
                />
              </Grid>
              <Grid xs={2} width={'160px'}>
                <RatingSemicircle
                  rating={Number(sectionRatings?.overlapping_claims)}
                  title={'Overlapping Claims'}
                />
              </Grid>
            </Grid>
          ) : (
            <Stack maxWidth={'200px'} direction={'column'} alignItems={'center'}>
              <RatingSemicircle rating={sectionRatings?.[selectedTab ?? '']} />
              {isReadMoreDataAvailable ? (
                <Button
                  sx={{
                    background: (theme) => theme.palette['linearGradient']['special'],
                    width: '100%',
                  }}
                  size='sm'
                  startDecorator={<TbBulb />}
                  onClick={scrollReadMoreIntoView}
                >
                  View deep-dive analysis
                </Button>
              ) : null}
            </Stack>
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};
