import { Box, styled } from '@mui/joy';

export const StyledHTML = styled(Box)(({ theme, fontSize }) => ({
  color: theme.vars.palette.text.primary,
  fontFamily: theme?.fontFamily?.body,
  fontSize: theme.vars.fontSize.md,
  fontStyle: 'normal',
  fontWeight: theme.vars.fontWeight.sm,
  lineHeight: theme.vars.lineHeight.md,
  ul: {
    paddingLeft: theme.spacing(4),
    li: {
      listStyle: 'disc',
    },
  },
  '.RichText3-paragraph': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: fontSize || theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.sm,
    lineHeight: theme.vars.lineHeight.md,
    '& > a': {
      color: theme.vars.palette['hyperlink']['text'],
      textDecoration: 'none',
      textUnderlineOffset: '0.15em',
      '&:hover': { textDecoration: 'underline' },
      fontFamily: theme?.fontFamily?.body,
      fontSize: fontSize || theme.vars.fontSize.md,
      fontStyle: 'normal',
      fontWeight: theme.vars.fontWeight.sm,
      lineHeight: theme.vars.lineHeight.md,
    },
    '& > img': {
      objectFit: 'contain',
      maxWidth: '750px',
      borderRadius: '24px',
    },
  },
  '& > hr': {
    height: '1px',
    width: '100%',
    backgroundColor: '#4d4d4d',
  },
  table: {
    width: '100%',
    td: {
      border: '1px solid #4d4d4d',
      padding: '12px 24px',
      //   paddingRight: '0px',
    },
  },
  '& > img': {
    objectFit: 'contain',
    maxWidth: '750px',
    borderRadius: '24px',
  },
  '& > h5': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: theme.vars.fontSize.xl,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.xl,
    lineHeight: theme.vars.lineHeight.md,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  '& > p': {
    color: theme.vars.palette.text.primary,
    fontFamily: theme?.fontFamily?.body,
    fontSize: fontSize || theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.md,
    lineHeight: theme.vars.lineHeight.md,
    marginBottom: theme.spacing(1),
    '& > a': {
      color: theme.vars.palette['hyperlink']['text'],
      textDecoration: 'none',
      textUnderlineOffset: '0.15em',
      '&:hover': { textDecoration: 'underline' },
      fontFamily: theme?.fontFamily?.body,
      fontSize: fontSize || theme.vars.fontSize.md,
      fontStyle: 'normal',
      fontWeight: theme.vars.fontWeight.sm,
      lineHeight: theme.vars.lineHeight.md,
    },
    '& > img': {
      objectFit: 'contain',
      maxWidth: '750px',
      borderRadius: '24px',
    },
    '& > i': {
      fontStyle: 'italic',
    },
  },
  '& > a': {
    color: theme.vars.palette['hyperlink']['text'],
    textDecoration: 'none',
    textUnderlineOffset: '0.15em',
    '&:hover': { textDecoration: 'underline' },
    fontFamily: theme?.fontFamily?.body,
    fontSize: fontSize || theme.vars.fontSize.md,
    fontStyle: 'normal',
    fontWeight: theme.vars.fontWeight.sm,
    lineHeight: theme.vars.lineHeight.md,
  },
  '& > div': {
    '& > table': {
      ' & > tr': {
        '& > th': {
          padding: theme.spacing(2),
          border: '1px solid #4D4D4D',
        },
        '& > td': {
          padding: theme.spacing(2),
          border: '1px solid #4D4D4D',
        },
      },
    },
  },
  '& > ol': {
    listStyle: 'auto',
    paddingLeft: theme.spacing(4),
  },
  '& .project-attribute-container': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    p: { ...theme.typography['body-md'], color: theme.palette.text.primary },
    a: {
      color: theme.vars.palette['hyperlink']['text'],
      textDecoration: 'none',
      textUnderlineOffset: '0.15em',
      '&:hover': { textDecoration: 'underline' },
    },
  },
}));
