import { Divider, Stack, useTheme } from '@mui/joy';

import CommonImage from '../../../../../components/Image';
import { StyledHTML } from '../StyledHTML';

const ImageAndDescription = ({ image, description }: any) => {
  const theme = useTheme();
  return (
    <Stack direction={'row'} justifyContent={'center'}>
      <Stack alignItems={'center'} justifyContent={'center'} sx={{ mr: 3, flex: 0.8 }}>
        <CommonImage
          src={image}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            background: theme.palette.background.surface,
            marginBottom: theme.spacing(1),
          }}
          isZoomable
        />
      </Stack>
      <Stack direction={'row'} gap={6} sx={{ flex: 1.2 }}>
        <Divider sx={{ height: '100%', width: 2 }} />
        <Stack sx={{ maxHeight: 500, overflowY: 'auto' }}>
          <StyledHTML
            fontSize={theme.vars.fontSize.lg}
            fontWeight={'md'}
            color='primary'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ImageAndDescription;
