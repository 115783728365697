import { useMemo } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Skeleton,
  Stack,
  Typography,
  TypographySystem,
} from '@mui/joy';
import { MdError } from 'react-icons/md';
import { useRecoilValue } from 'recoil';

import MessagePage from '../../../../../components/MessagePage';
import { track } from '../../../../../services/analytics';
import {
  EVENT_ESR_RATING_PAGE,
  EVNT_esr_comparison_view_toggled,
} from '../../../../../services/analytics/events';
import { projectState } from '../../../../../stores/project';
import { brand } from '../../../../../theme/brand';
import ProjectComparisonLoader from '../loaders/ProjectComparisonLoader';
import RiskAreaComparison from '../RiskAreaComparison';

const ProjectComparison: React.FC<{
  data: any;
  error: boolean;
  isLoading: boolean;
  projectComparisonType: 'category' | 'safeguard';
  handleProjectComparisonType: (value: 'category' | 'safeguard') => void;
}> = ({ data, error, projectComparisonType, isLoading, handleProjectComparisonType }) => {
  const { projectData } = useRecoilValue(projectState);
  const risks = useMemo(() => {
    if (projectComparisonType === 'safeguard' && data) {
      const safeguardRisks: any[] = [];
      const map = new Map();
      data.riskLevelCounts.forEach((risk: any) => {
        if (map.has(risk.categoryName)) {
          const index = map.get(risk.categoryName);
          safeguardRisks[index].areas.push(risk);
        } else {
          map.set(risk.categoryName, safeguardRisks.length);
          safeguardRisks.push({
            categoryName: risk.categoryName,
            areas: [risk],
          });
        }
      });
      return {
        ...data,
        riskLevelCounts: safeguardRisks,
      };
    }
    return data;
  }, [data, projectComparisonType]);

  return (
    <Stack gap={2}>
      {data?.projectCount >= 2 && !isLoading ? (
        <Typography level='body-md' color='primary'>
          The following histograms show risk level rating distributions from all projects that have
          been assessed for ESR by Calyx Global compared to the ratings of this project. Safeguard
          areas are grouped into social, governance, and environmental risks. The highest risk level
          from each category is represented on the graphs. No project is entirely without risk.
        </Typography>
      ) : (
        isLoading && (
          <>
            <Skeleton variant='text' sx={{ height: (theme) => theme.spacing(2) }} />
            <Skeleton variant='text' sx={{ height: (theme) => theme.spacing(2) }} />
          </>
        )
      )}
      {error ? (
        <Stack gap={1} pt={1}>
          <Typography level='h4'>
            Environmental and social risks comparison for {projectData?.projectType} projects
          </Typography>
          <MessagePage
            title='Something went wrong'
            description='We couldn’t load the information. Refresh the page to try again.'
            hasHeading={false}
            iconProps={{
              size: 56,
            }}
            headingProps={{
              level: 'h4',
            }}
            descriptionProps={{
              level: 'title-md',
            }}
            Icon={MdError}
            action={() => {
              window.location.reload();
            }}
            actionText='Refresh'
            border={(theme) => `1px solid ${theme.palette['border']['border']}`}
            background={(theme) => theme.palette.background.body}
            borderRadius={(theme) => theme.spacing(1)}
          />
        </Stack>
      ) : data?.projectCount >= 2 || isLoading ? (
        <>
          <Stack gap={1} pt={1}>
            <Typography level='h4'>
              Environmental and social risks comparison for {projectData?.projectType} projects
            </Typography>
            {isLoading ? (
              <Skeleton variant='text' sx={{ height: (theme) => theme.spacing(2) }} />
            ) : (
              <Typography level='title-md'>
                {data?.projectCount || '0'} {projectData?.projectType} projects have been screened
                for ESR
              </Typography>
            )}
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Show by
            </Typography>
            <ButtonGroup size='sm' sx={{ justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  track(EVNT_esr_comparison_view_toggled, EVENT_ESR_RATING_PAGE, {
                    toggle_applied: 'Safeguard category',
                    project_id: projectData?.projectId,
                    project_name: projectData?.title,
                  });
                  handleProjectComparisonType('category');
                }}
                variant={projectComparisonType === 'category' ? 'solid' : 'outlined'}
                color='primary'
              >
                Safeguard category
              </Button>
              <Button
                onClick={() => {
                  track(EVNT_esr_comparison_view_toggled, EVENT_ESR_RATING_PAGE, {
                    toggle_applied: 'Safeguard area',
                    project_id: projectData?.projectId,
                    project_name: projectData?.title,
                  });
                  handleProjectComparisonType('safeguard');
                }}
                variant={projectComparisonType === 'safeguard' ? 'solid' : 'outlined'}
                color='primary'
              >
                Safeguard area
              </Button>
            </ButtonGroup>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap={3} py={1}>
            <Stack direction={'row'} alignItems={'center'} gap={2}>
              <Box
                sx={{
                  width: 0,
                  height: 0,
                  borderLeft: '10px solid transparent',
                  borderRight: '10px solid transparent',
                  transform: 'rotate(180deg)',
                  borderTop: `20px solid ${brand.function.dark.fuel[100]}`, // Use your custom color here
                }}
              ></Box>
              <Typography color='primary' fontSize={21.199}>
                This Project
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap={2}>
              <Box
                sx={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: (theme) => theme.palette['SDGDisplay'][11],
                }}
              ></Box>
              <Typography color='primary' fontSize={21.199}>
                Other projects
              </Typography>
            </Stack>
          </Stack>
          {isLoading ? (
            <ProjectComparisonLoader />
          ) : (
            <>
              {projectComparisonType === 'category' && (
                <RiskAreaComparison data={data?.riskLevelCounts || []} showMarkPoint />
              )}
              {projectComparisonType === 'safeguard' &&
                (risks?.riskLevelCounts || []).map((risk) => (
                  <Stack key={risk.categoryName} gap={4}>
                    <Stack gap={'10px'}>
                      <Typography
                        level={'sub1' as keyof TypographySystem}
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        {risk.categoryName} risks
                      </Typography>
                      <Divider />
                    </Stack>
                    <Box mb={2}>
                      <RiskAreaComparison data={risk.areas} showMarkPoint />
                    </Box>
                  </Stack>
                ))}
            </>
          )}
        </>
      ) : (
        !isLoading && (
          <Card
            sx={{
              background: (theme) => theme.palette.background.body,
              border: (theme) => `1px solid ${theme.palette['border']['border']}`,
            }}
          >
            <Typography fontSize={'lg'} fontWeight={'md'}>
              The project comparison is not available because this is the only{' '}
              {projectData?.projectType || ''} project that has been screened for ESRs.
            </Typography>
          </Card>
        )
      )}
    </Stack>
  );
};

export default ProjectComparison;
