export const hasBaseUrl = (src) => {
  // Regular expression to check if the src starts with a scheme (e.g., http://, https://, ftp://, etc.)
  const urlPattern = /^[a-zA-Z][a-zA-Z\d+\-.]*:\/\//;
  return urlPattern.test(src);
};

export const getProjectAttributes = (data: any[]) => {
  if (!data)
    return {
      attributeNames: [],
      attributeDetails: [],
    };
  const filteredValues = data.filter((item) => item.attribute_value === true);
  const attributeNames = filteredValues.map((item) => item?.attribute?.display_name || '');
  const attributeDetails = filteredValues.map((item) => ({
    ...item.attribute,
    attribute_extra_content: item.attribute_extra_content,
  }));

  return {
    attributeNames,
    attributeDetails,
  };
};

export const riskMapper = (riskLevel) => {
  const values = {
    critical: ['Max risk: critical'],
    high: ['Max risk: high', 'Max risk: critical'],
    moderate: ['Max risk: moderate', 'Max risk: high', 'Max risk: critical'],
    low: ['Max risk: low', 'Max risk: moderate', 'Max risk: high', 'Max risk: critical'],
    no_risk: ['Max risk: low', 'Max risk: moderate', 'Max risk: high', 'Max risk: critical'],
    insufficient_evidence: [
      'Max risk: low',
      'Max risk: moderate',
      'Max risk: high',
      'Max risk: critical',
    ],
    no_screening: ['Not screened'],
  };

  return values[riskLevel];
};
