import { Grid, Stack, Skeleton } from '@mui/joy';
const ProjectComparisonLoader = () => {
  return (
    <Grid container gridColumn={3} width={1} spacing={5} mb={4}>
      {[...new Array(3).fill(null)].map((_, index) => (
        <Grid key={index} xs={4} sx={{ alignContent: 'center' }}>
          <Stack
            gap={1.2}
            sx={{
              width: 1,
              height: 1,
            }}
          >
            <Skeleton variant='text' sx={{ height: (theme) => theme.spacing(2) }} />
            <Skeleton variant='text' sx={{ height: (theme) => theme.spacing(2) }} />
            <Skeleton variant='rectangular' sx={{ height: 400 }} />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectComparisonLoader;
