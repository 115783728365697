import React from 'react';

import { Card, Grid, Stack, Typography } from '@mui/joy';

import { getRiskAreaColorCode } from '../../../../../utils/constants/colorCode';
import { screeningIconMap } from '../../../constants';
import { RisksType } from '../../types';

const SummaryRiskLevel: React.FC<{
  data: RisksType[];
  scrollReadMoreIntoView: (param: React.RefObject<HTMLDivElement> | string) => void;
}> = ({ data, scrollReadMoreIntoView }) => {
  return (
    <Stack gap={2} my={3}>
      <Typography level='h4' color='primary'>
        Summary of risk level by safeguard area
      </Typography>
      {(data || []).map((risk) =>
        risk.name === 'insufficient_evidence' &&
        (risk?.safeguard_areas || []).length === 0 ? null : (
          <Stack gap={2} key={risk.name}>
            <Stack direction={'row'} gap={2}>
              <Typography
                fontWeight={'xl'}
                textAlign={'right'}
                color='primary'
                sx={{ width: 80, pt: (risk?.safeguard_areas || []).length === 0 ? 0 : 2 }}
              >
                {risk.display_name}
              </Typography>
              <Stack direction={'row'} flexWrap={'wrap'} gap={1} flex={1}>
                {(risk?.safeguard_areas || []).length === 0 && (
                  <Stack justifyContent={'center'} height={'fit-content'}>
                    <Typography
                      level='body-md'
                      sx={{ color: (theme) => theme.palette.text.tertiary }}
                    >
                      {risk.name === 'no_risks_identified'
                        ? 'No safeguard areas without identified risks'
                        : `No ${risk.display_name} risks identified`}
                    </Typography>
                  </Stack>
                )}
                {(risk?.safeguard_areas || []).map((area) => (
                  <Card
                    variant='outlined'
                    onClick={() => scrollReadMoreIntoView(area.name)}
                    sx={{
                      width: '24%',
                      minWidth: '207.5px',
                      maxWidth: 400,
                      minHeight: '56px',
                      maxHeight: 'px',
                      height: 'auto',
                      p: 1,
                      background: (theme) => theme.palette.background.surface,
                      borderColor: (theme) => `${getRiskAreaColorCode(theme, true)[risk.name]}`,
                      borderWidth: '2px',
                      cursor: 'pointer',
                      '&:hover': {
                        background: (theme) => `${theme.palette.primary.outlinedColor}`,
                      },
                    }}
                  >
                    <Grid
                      container
                      gap={1}
                      sx={{ flexGrow: 1, flexWrap: 'nowrap' }}
                      key={area.name}
                    >
                      <Grid alignItems='center' display={'flex'}>
                        {screeningIconMap[area.name]?.icon({ width: 32, height: 32 }) || ''}
                      </Grid>
                      <Grid alignItems='center' display={'flex'} sx={{ width: '80%' }}>
                        <Stack>
                          <Typography
                            fontSize={'sm'}
                            lineHeight={'18.62px'}
                            fontWeight={'md'}
                            color='primary'
                          >
                            {area.display_name}
                          </Typography>
                          {risk.name === 'no_risks_identified' && area?.site_risk && (
                            <Typography
                              fontSize={'xs'}
                              fontWeight={'md'}
                              lineHeight={'18.62px'}
                              sx={{ color: (theme) => theme.palette.text.tertiary }}
                            >
                              No project risk, some risk related to facility or site
                            </Typography>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Stack>
            </Stack>
          </Stack>
        )
      )}
    </Stack>
  );
};

export default SummaryRiskLevel;
