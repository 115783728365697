import { Box, Skeleton, Stack, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';

import Paywall from '../../components/Paywall';
import { makeAuthenticatedGetRequest } from '../../services/axios';
import { whereToBuy } from '../../services/axios/endpoints';
import { useFetchUserPermissions } from '../../utils/hooks/useFetchUserPermission';

import { WhereToBuyCard } from './components/WhereToBuyCard';

export const WhereToBuy = () => {
  const { hasPermissionForWhereToBuy } = useFetchUserPermissions();

  const { data, isLoading } = useQuery({
    queryKey: [`where-to-buy-projects`],
    queryFn: async () => makeAuthenticatedGetRequest(whereToBuy),
    select: (data) => data?.data?.projects,
    enabled: hasPermissionForWhereToBuy,
  });
  return (
    <Box position={'relative'}>
      <Paywall
        isLoadingComplete={true}
        showPaywall={!hasPermissionForWhereToBuy}
        headingText='Find credits with “Where to Buy”'
        bodyText='Upgrade your subscription to identify and connect with sellers of high-quality credits.'
      />
      <Box paddingBottom={12} maxWidth={'90vw'} minWidth={'lg'} margin={'auto'} paddingTop={3}>
        <Stack gap={1}>
          <Stack>
            <Typography level='h4' color='primary'>
              Where to Buy
            </Typography>
            <Typography level='title-lg' color='primary'>
              Access higher-rated carbon credits
            </Typography>
          </Stack>
          <Typography level='body-md' color='primary'>
            This feature helps connect buyers with sellers of credits that have a Calyx Global GHG
            Rating of BB and above. The listings below include sellers with available inventory of
            these credits, as well as sellers with access to them (but that may not be “holding”
            inventory). Calyx Global does not act as an intermediary or agent on behalf of any
            seller, and receives no compensation or commission from any seller for providing this
            information. The information is provided "as is" without any representation or warranty
            whatsoever and does not constitute any form of advice or recommendation. Calyx Global
            accepts no liability whatsoever for any loss, expense, cost or liability that may arise
            from the subscriber’s decision to purchase any listed credit.
          </Typography>
        </Stack>
        {!hasPermissionForWhereToBuy ? null : isLoading ? (
          <Stack spacing={2} marginTop={3}>
            {Array(5)
              .fill('')
              .map(() => {
                return <Skeleton variant='rectangular' height={'2.5rem'} animation='wave' />;
              })}
          </Stack>
        ) : (
          data?.map((item: any, index: number) => {
            return (
              <WhereToBuyCard key={item?.project_id} isChild={false} data={item} index={index} />
            );
          })
        )}
      </Box>
    </Box>
  );
};
