import { Stack } from '@mui/joy';

import { StyledHTML } from '../StyledHTML';

const FurtherResearch = ({ data }) => {
  return (
    <Stack gap={2}>
      <StyledHTML
        fontWeight={'md'}
        fontSize={'lg'}
        color='primary'
        dangerouslySetInnerHTML={{ __html: data?.description }}
      />
      {/* <StyledTable borderAxis='both'>
        <thead>
          <tr>
            <th style={{ width: '15%' }}>Safeguard area</th>
            <th style={{ width: '25%' }}>Risk identified</th>
            <th style={{ width: '10%' }}>Risk level</th>
            <th style={{ width: '50%' }}>Next steps</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Benefit sharing</td>
            <td>Stakeholder participation process not aligned with international best practices</td>
            <StyledTD riskLevel='high'>High</StyledTD>
            <td>
              We suggest conducting interviews with relevant stakeholders to ensure whether IP were
              fully engaged in project consultation.
            </td>
          </tr>
        </tbody>
      </StyledTable> */}
    </Stack>
  );
};

export default FurtherResearch;
