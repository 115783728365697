import React from 'react';

import { Modal, ModalOverflow, ModalDialog, Typography, ModalClose } from '@mui/joy';
import HubspotForm from 'react-hubspot-form';

import { PORTAL_ID } from '../../utils/constants';
import useThemedFrame from '../../utils/hooks/useThemedFrame';

interface RequestEsrScreeningProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => void;
  isModalOpen: boolean;
}

const FORM_ID = '1d8f13e7-992d-4e8e-800b-cf9f48a7f183';

const RequestEsrScreening: React.FC<RequestEsrScreeningProps> = ({
  setIsModalOpen,
  isModalOpen,
  onSubmit,
}) => {
  const { updateIframeTheme } = useThemedFrame();

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <ModalOverflow>
        <ModalDialog layout={'center'} sx={{ width: '55rem' }}>
          <ModalClose sx={{ top: '36px', right: '26px' }} />
          <Typography level='h1'>Request an in-depth program screening</Typography>
          <Typography level='body-md'>
            Please complete this form to request a comprehensive program screening report. After
            reviewing the request, we will follow up with a price quote.
          </Typography>
          <HubspotForm
            region='na1'
            portalId={PORTAL_ID}
            formId={FORM_ID}
            onReady={updateIframeTheme} // Assuming this is the function passed as prop
            loading={<div>Loading...</div>}
            onSubmit={() => onSubmit && onSubmit()}
          />
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default RequestEsrScreening;
