import { Box, Card, Stack, Typography } from '@mui/joy';

import { getChipInfoFromStatusNumber } from '../constants';

import { HTMLRenderer } from './HTMLRenderer';

interface PropTypes {
  content: Array<any>;
  tableType: string;
  caption: string;
}

export const Table = (props: PropTypes) => {
  const { content, tableType, caption } = props;
  return (
    <Box>
      <Box display={'grid'} gridTemplateColumns={'repeat(auto-fit, minmax(300px, 1fr))'} gap={3}>
        {content?.map(({ type, description, status }) => {
          const { icon } = getChipInfoFromStatusNumber(Number(status));
          return !description && tableType !== 'redd_leakage_table' ? null : (
            <Card
              sx={(theme) => {
                return { padding: 2, background: theme.palette.primary.outlinedColor };
              }}
            >
              <Stack spacing={2}>
                <Stack direction={'row'} alignItems={'center'} gap={2}>
                  {icon}
                  <Typography fontSize={'md'} fontWeight={'xl'} color='primary'>
                    {type}
                  </Typography>
                </Stack>
                <Typography
                  level='body-md'
                  sx={(theme) => {
                    return { color: theme.palette.text.primary };
                  }}
                >
                  {!!description ? (
                    <HTMLRenderer
                      dangerouslySetInnerHTML={{ __html: description }}
                      colorType='secondary'
                    />
                  ) : null}
                </Typography>
              </Stack>
            </Card>
          );
        })}
      </Box>
      {!!caption ? (
        <Typography my={3} level='body-md' fontStyle={'italic'}>
          <HTMLRenderer dangerouslySetInnerHTML={{ __html: caption }} colorType='secondary' />
        </Typography>
      ) : null}
    </Box>
  );
};
