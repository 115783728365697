import { AccordionSummary, ListItem, styled, Table } from '@mui/joy';

export const StyledTable = styled(Table)(({ theme }) => ({
  '--TableCell-cornerRadius': theme.spacing(1),
  '--TableCell-borderColor': theme.palette.primary.outlinedBorder,
  '--TableCell-headBackground': theme.palette.background.body,
  '--TableCell-footBackground': theme.palette.background.body,
  '--Table-headerUnderlineThickness': '3px',
  '--TableCell-paddingY': theme.spacing(2),
  '--TableCell-paddingX': theme.spacing(2),
  background: theme.palette.background.surface,
  th: {
    color: theme.palette.text.primary,
    fontWeight: theme.vars.fontWeight.xl,
    fontSize: theme.vars.fontSize.md,
    whiteSpace: 'normal',
    verticalAlign: 'middle !important',
  },
  td: {
    color: theme.palette.text.primary,
    fontWeight: theme.vars.fontWeight.md,
    fontSize: theme.vars.fontSize.md,
    verticalAlign: 'middle !important',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)<{ active: boolean }>(
  ({ active, theme }) => ({
    button: {
      color: `${active ? theme.palette.primary.plainActiveBg : theme.palette.text.primary} !important`,
      padding: '34px 10px 34px 10px',
      cursor: 'pointer',
    },
  })
);

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.vars.fontSize.md,
  fontWeight: theme.vars.fontWeight.md,
  display: 'list-item',
  padding: 0,
  minBlockSize: 'fit-content',
}));
