import React from 'react';

import { Modal, ModalOverflow, ModalDialog, Typography, ModalClose } from '@mui/joy';
import HubspotForm from 'react-hubspot-form';

import { PORTAL_ID } from '../../../../utils/constants';
import useThemedFrame from '../../../../utils/hooks/useThemedFrame';

interface RequestEsrReportProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => void;
  isModalOpen: boolean;
}

const FORM_ID = 'fb0cea44-4283-40f0-837a-9df219d2769c';

const RequestEsrReport: React.FC<RequestEsrReportProps> = ({
  setIsModalOpen,
  isModalOpen,
  onSubmit,
}) => {
  const { updateIframeTheme } = useThemedFrame();

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <ModalOverflow>
        <ModalDialog layout={'center'} sx={{ width: '55rem' }}>
          <ModalClose sx={{ top: '36px', right: '26px' }} />
          <Typography level='h1'>Request an ESR screening</Typography>
          <Typography level='body-md'>
            Please complete this form to request a project-specific ESR screening. After reviewing
            the request, we will follow up with a price quote.
          </Typography>
          <HubspotForm
            region='na1'
            portalId={PORTAL_ID}
            formId={FORM_ID}
            onReady={updateIframeTheme} // Assuming this is the function passed as prop
            loading={<div>Loading...</div>}
            onSubmit={() => onSubmit && onSubmit()}
          />
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default RequestEsrReport;
