import { useEffect, useLayoutEffect } from 'react';

import { MdError } from 'react-icons/md';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  ScrollRestoration,
  useLocation,
} from 'react-router-dom';

import MessagePage from './components/MessagePage';
import { ProtectedRoute } from './components/ProtectedRoute';
import {
  Home,
  Contact,
  Blog,
  BlogDescription,
  PinnedBlogDescription,
  ResourceDescription,
  DonnasNotes,
  Resource,
  Project,
  EnvironmentalAndSocialRisks,
  ExecutiveSummary,
  GhgRating,
  ProjectComparisonPage,
  RelatedContent,
  SDGRating,
  RetireeInsights,
  RetireeInsightsDrillDown,
  SearchPage,
  WhereToBuy,
  SignUp,
  Programs,
} from './pages';
import { page } from './services/analytics';
import Sentry from './services/sentry';

const NavigateToProfileSettings = () => {
  useLayoutEffect(() => {
    window.location.href = process.env.IS_PRODUCTION_APP
      ? 'https://auth.calyxglobal.com/account'
      : 'https://auth.calyxstaging.com/account';
  }, []);
  return <></>;
};

const AppLayout = () => {
  const location = useLocation();
  useEffect(() => {
    page();
  }, [location]);

  return (
    <Sentry.ErrorBoundary
      fallback={
        <MessagePage
          title="Oops, that's our bad!"
          description="We're not exactly sure what happened, rest assured we've been notified about this issue and this will be fixed soon!"
          Icon={MdError}
          hasAction
        />
      }
    >
      <ScrollRestoration />
      <Outlet />
    </Sentry.ErrorBoundary>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: (
      <MessagePage
        title='Page not found'
        description='The page you are looking for doesn’t exist or has been moved.'
        hasAction
      />
    ),
    children: [
      {
        path: '/',
        element: <ProtectedRoute />,
        children: [
          {
            path: `/`,
            element: <Home />,
          },
          {
            path: '/login',
            element: <Navigate to='/' />,
          },
          {
            path: 'blog_detail_dist',
            element: <PinnedBlogDescription />,
          },
          {
            path: `/`,
            children: [
              {
                path: 'blog',
                element: <Blog />,
              },
              {
                path: 'blog-detail',
                element: <BlogDescription />,
              },
              {
                path: 'resource',
                element: <Resource />,
              },
              {
                path: 'resource-detail',
                element: <ResourceDescription />,
              },
              {
                path: 'note',
                element: <DonnasNotes />,
              },
            ],
          },
          {
            path: `/search`,
            element: <SearchPage />,
          },
          {
            path: `/`,
            element: <Project />,
            children: [
              { path: 'executive_summary', element: <ExecutiveSummary /> },
              { path: 'ghg_rating', element: <GhgRating /> },
              {
                path: 'esr_rating',
                element: <EnvironmentalAndSocialRisks />,
                children: [
                  {
                    path: 'detailed_risk_findings',
                    element: <EnvironmentalAndSocialRisks />,
                  },
                  {
                    path: 'project_comparison',
                    element: <EnvironmentalAndSocialRisks />,
                  },
                  {
                    path: 'further_research',
                    element: <EnvironmentalAndSocialRisks />,
                  },
                  {
                    path: 'sources_and_methodology',
                    element: <EnvironmentalAndSocialRisks />,
                  },
                ],
              },
              {
                path: 'ess_rating',
                element: <Navigate to={`/esr_rating${window.location.search}`} replace />,
              },
              {
                path: 'ess_rating/detailed_risk_findings',
                element: (
                  <Navigate
                    to={`/esr_rating/detailed_risk_findings${window.location.search}`}
                    replace
                  />
                ),
              },
              {
                path: 'ess_rating/project_comparison',
                element: (
                  <Navigate
                    to={`/esr_rating/project_comparison${window.location.search}`}
                    replace
                  />
                ),
              },
              {
                path: 'ess_rating/further_research',
                element: (
                  <Navigate to={`/esr_rating/further_research${window.location.search}`} replace />
                ),
              },
              {
                path: 'ess_rating/sources_and_methodology',
                element: (
                  <Navigate
                    to={`/esr_rating/sources_and_methodology${window.location.search}`}
                    replace
                  />
                ),
              },
              { path: 'related_content', element: <RelatedContent /> },
              { path: 'project_comparison', element: <ProjectComparisonPage /> },
              { path: 'sdg_rating', element: <SDGRating /> },
            ],
          },
          {
            path: `/where_to_buy`,
            element: <WhereToBuy />,
          },
          {
            path: 'retirement_insights',
            element: <RetireeInsights />,
          },
          {
            path: 'retirement_insights/:retiree',
            element: <RetireeInsightsDrillDown />,
          },
          {
            path: 'contact',
            element: <Contact />,
          },
          {
            path: 'profile',
            element: <NavigateToProfileSettings />,
          },
          {
            path: 'dashboard',
            element: <Navigate to='/' />,
          },
          {
            path: '/programs',
            element: <Programs />,
          },
        ],
      },
      {
        path: '/signup',
        element: <SignUp />,
      },
    ],
  },
]);

export const Routes = () => {
  return <RouterProvider router={router} />;
};
