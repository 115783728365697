import { useMemo, useRef } from 'react';

import { useColorScheme, useTheme } from '@mui/joy';
import ReactECharts from 'echarts-for-react';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../../stores/project';
import { brand } from '../../../../theme/brand';

import { createTooltipHTML } from './createTooltipHTML';

export const ComparisonGraph = ({ data: details, showMarkPoint = false, align }) => {
  const theme = useTheme();
  const { mode } = useColorScheme();
  const chartRef = useRef<any>(null);
  const { projectData } = useRecoilValue(projectState);
  const data = useMemo(() => details?.data, [details]);
  const tooltipPositionCache = useRef({}).current;

  const chartOptions = useMemo(() => {
    const seriesData = [
      data.no_risks_identified,
      data.low,
      data.moderate,
      data.high,
      data.critical,
    ];
    const indexMap = {
      no_risks_identified: 0,
      low: 1,
      moderate: 2,
      high: 3,
      critical: 4,
    };
    const percentages = seriesData.map((d) => d.value);
    const highestPercentage = Math.max(...percentages);
    const highestIndex = indexMap[details.thisProjectLevel];

    return data
      ? {
          backgroundColor: 'transparent',
          grid: {
            left: '10%',
            right: '10%',
            top: '10%',
            bottom: '15%',
            containLabel: true,
            show: false,
          },
          xAxis: {
            type: 'category',
            data: ['None / \n unknown', 'Low', 'Mod', 'High', 'Crit'],
            axisLabel: {
              interval: 0,
              align: 'center',
              style: {
                fontSize: theme.vars.fontSize.sm,
                color: theme.palette.text.secondary,
                fontWeight: theme.vars.fontWeight.md,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
          yAxis: {
            type: 'value',
            min: 0,
            max: highestPercentage,
            name: 'Percent of\nscreened projects',
            nameGap: 30,
            nameLocation: 'middle',
            boundaryGap: false,
            interval: 'auto',
            nameTextStyle: {
              fontSize: theme.vars.fontSize.sm,
              color: '#000',
              fontWeight: theme.vars.fontWeight.md,
            },
            axisTick: {
              show: true,
              lineStyle: {
                background: theme.palette.text.primary,
              },
            },
            nameSpacing: 20,
            axisLabel: {
              formatter: (value) => {
                if (value === 0) return '0%';
                if (value === highestPercentage) return `${highestPercentage}%`;
                return '';
              },
              color: theme.palette.text.primary,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
          series: [
            {
              type: 'bar',
              data: seriesData,
              itemStyle: {
                color: theme.palette['SDGDisplay'][11],
              },
              barWidth: '95%',
              markLine: {
                symbol: ['none', 'none'],
                data: [
                  {
                    yAxis: highestPercentage,
                    label: {
                      show: false,
                    },
                    lineStyle: {
                      color: 'rgb(229 229 229)',
                      type: 'solid',
                      width: 1,
                    },
                    z: 10,
                  },
                  {
                    yAxis: 0,
                    label: {
                      show: false,
                    },
                    lineStyle: {
                      color: 'rgb(229 229 229)',
                      type: 'solid',
                      width: 1,
                    },
                  },
                ],
              },
              markPoint: showMarkPoint
                ? {
                    symbol: 'triangle',
                    symbolSize: 20,
                    symbolOffset: [0, 50],
                    data: [
                      {
                        name: 'Highest Risk',
                        coord: [highestIndex, 0],
                        itemStyle: {
                          color: brand.function.dark.fuel[100],
                        },
                        label: {
                          show: false,
                        },
                      },
                    ],
                  }
                : null,
            },
          ],
          tooltip: {
            trigger: 'axis',
            enterable: true,
            padding: 0,
            style: {
              borderRadius: theme.vars.radius.md,
            },
            position: function (pos, params, dom, __, size) {
              const barIndex = params[0]?.dataIndex;
              const stablePositionCache = tooltipPositionCache[barIndex];

              if (stablePositionCache) {
                return stablePositionCache;
              }

              const calculatedPosition = {
                right:
                  align === 'right'
                    ? -(pos[0] + dom.offsetWidth - size.viewSize[0])
                    : size.viewSize[0] - pos[0],
                bottom: pos[1],
              };

              tooltipPositionCache[barIndex] = calculatedPosition;
              return calculatedPosition;
            },
            formatter: (params) => {
              const names = {
                mod: 'moderate',
                crit: 'critical',
                high: 'high',
                low: 'low',
              };
              const title = `${projectData?.projectType} projects with ${params[0]?.dataIndex === 0 ? 'no ' : names[params[0]?.name?.toLowerCase()]} social risk`;
              const items = (params[0]?.data?.projects || []).map((project) => ({
                ...project,
                name: project.project_name,
                link: `/executive_summary?standard=${project.ghg_standard}&id=${project.project_id}&cp_no=${project.cp_no}`,
                code: `${project.ghg_standard}${project.project_id}`,
              }));

              return createTooltipHTML(title, items, theme);
            },
          },
        }
      : {};
  }, [align, data, details.thisProjectLevel, projectData?.projectType, showMarkPoint, theme]);

  return (
    <ReactECharts
      ref={chartRef}
      option={chartOptions}
      style={{ height: '300px', width: '100%', marginTop: 'auto' }}
      theme={mode}
    />
  );
};

export default ComparisonGraph;
