import { useMemo } from 'react';

import { Box, Typography, Stack, Divider } from '@mui/joy';

import { dateFormat } from '../../../../../utils/functions/date';

const TimelineItem = ({ title, description, updateDate }) => {
  const date = dateFormat(updateDate);
  const formatedDate = `${date.month} ${date.year}`;
  const limitedDescription = useMemo(
    () => (description?.length > 150 ? `${description?.slice(0, 150)}...` : description),
    [description]
  );
  return (
    <Stack direction='row' alignItems='center' gap={1} spacing={2} mb={2}>
      <Stack gap={2}>
        <Stack pr={4} maxWidth={400}>
          <Stack direction={'row'} gap={1}>
            <Typography fontWeight={'xl'} fontSize={'md'} color='primary'>
              {formatedDate}
            </Typography>
            <Divider sx={{ height: (theme) => theme.spacing(3), width: '1px' }} />
            <Typography level='body-md' color='primary'>
              {title}
            </Typography>
          </Stack>
          <Typography
            fontWeight={'md'}
            fontSize={'sm'}
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            {limitedDescription}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={1} width={'100%'}>
          <Box
            sx={{
              width: 16,
              height: 16,
              borderRadius: '50%',
              background: (theme) => `${theme.palette.primary.plainDisabledColor}`,
            }}
          />
          <Box
            sx={{
              flex: 1,
              borderBottom: '3px solid',
              borderColor: (theme) => `${theme.palette['divider']}`,
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const ScreeningTimeline = ({ data }) => {
  return (
    <Stack sx={{ my: 1 }} gap={2}>
      <Typography level='h4' color='primary'>
        Screening timeline
      </Typography>
      <Stack direction={'row'} flexWrap={'wrap'} alignItems={'end'} gap={1} py={2}>
        <Box
          sx={{
            width: '30px',
            borderBottom: '3px solid',
            mb: 3,
            borderColor: (theme) => `${theme.palette['divider']}`,
            ml: 1,
          }}
        />
        {data.map((item, index) => (
          <TimelineItem
            title={index === 0 ? 'Initial screening' : 'Screening updated'}
            description={item.description}
            updateDate={item.update_date}
            key={index}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default ScreeningTimeline;
