import { useCallback, useMemo, useState } from 'react';

import { Badge, Box, Chip, Grid, IconButton, List, Typography, styled, useTheme } from '@mui/joy';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { track } from '../../../services/analytics';
import { EVENT_EXECUTIVE_SUMMARY_PAGE } from '../../../services/analytics/events';
import { projectState } from '../../../stores/project';
import { projectSections } from '../constants';

const StyledGrid = styled(Grid)<{ isDisabled: boolean; isActive: boolean; isExpanded: boolean }>(
  ({ theme, isDisabled, isActive, isExpanded }) => ({
    border: isActive && isExpanded ? `1px solid ${theme.palette.primary.outlinedActiveBg}` : 'none',
    background: isActive && isExpanded ? theme.palette.primary.outlinedHoverBg : '',
    color: theme.palette.text.primary,
    borderRadius: theme.radius.sm,
    marginLeft: isExpanded ? theme.spacing(2) : 0,
    marginRight: isExpanded ? theme.spacing(2) : 0,
    // flexWrap: 'nowrap',
    ':hover': isDisabled
      ? {}
      : {
          background: theme.palette.primary.outlinedHoverBg,
        },
  })
);

const FadingText = styled(Typography)<{ isActive: boolean }>(({ isActive, theme }) => ({
  color: 'inherit',
  fontWeight: isActive ? theme.fontWeight.xl : 'inherit',
  animation: 'fade 1s ease',
  '@keyframes fade': {
    '0%': {
      opacity: 0,
      display: 'none',
    },
    '20%': {
      display: 'none',
    },
    '100%': {
      opacity: 1,
      display: 'block',
    },
  },
}));

const FadeChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.primary.solidBg,
  color: theme.palette.primary.solidColor,
  animation: 'fade 1s ease',
  '@keyframes fade': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

const ProjectSidebar: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const { projectData, blogs, essRatingData, projectComparisonData, esrBadge } =
    useRecoilValue(projectState);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const showBadge = useMemo(
    () => !esrBadge.get(`${projectData?.projectId}`),
    [esrBadge, projectData?.projectId]
  );
  const isActive = (id: string) => {
    return !!pathname.startsWith(`/${id}`);
  };

  const isDisabled = useCallback(
    (id: string) => {
      switch (id) {
        case 'sdg_rating': {
          const grade = projectData?.sdgRatingDetail?.grade;
          return (
            (grade && (grade.toLowerCase() === 'n/a' || grade.toLowerCase() === 'no cert')) ||
            !grade
          );
        }
        case 'esr_rating': {
          return !essRatingData;
        }
        case 'project_comparison': {
          const data = projectComparisonData?.allProjectRatingData;
          return !data || data?.length === 0;
        }
        case 'related_content': {
          return (blogs || []).length === 0;
        }
      }
    },
    [
      blogs,
      essRatingData,
      projectComparisonData?.allProjectRatingData,
      projectData?.sdgRatingDetail?.grade,
    ]
  );

  return (
    <>
      <Box
        sx={{
          width: isExpanded ? 'auto' : '65px',
          minWidth: isExpanded ? '350px' : '',
          height: '100%',
          borderRight: 1,
          borderColor: theme.palette.primary.outlinedBorder,
          background: theme.palette.background.surface,
          position: 'fixed',
          transition: '0.2s min-width ease',
          zIndex: 1,
          px: isExpanded ? 2 : 0,
          py: 4,
        }}
        onMouseEnter={() => !isExpanded && setIsExpanded(true)}
        onMouseLeave={() => isExpanded && setIsExpanded(false)}
      >
        <Box>
          <List sx={{ p: 0 }}>
            {projectSections.map(({ id, title, Icon, event, badge }) => {
              const disabled = isDisabled(id);
              const active = isActive(id);
              return (
                <StyledGrid
                  container
                  key={id}
                  sx={{
                    mb: 3,
                    p: 1,
                    mx: 0,
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    opacity: disabled ? 0.5 : 1,
                  }}
                  isDisabled={disabled}
                  isActive={active}
                  isExpanded={isExpanded}
                  onClick={() => {
                    track(event, EVENT_EXECUTIVE_SUMMARY_PAGE);
                    if (!disabled) {
                      navigate({
                        pathname: `/${id}`,
                        search,
                      });
                    }
                  }}
                >
                  <Grid
                    xs={1}
                    width={!isExpanded ? 1 : '50px'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <IconButton
                      variant='plain'
                      sx={{
                        p: 0.8,
                        background:
                          active && !isExpanded
                            ? theme.palette.primary.outlinedHoverBg
                            : 'transparent',
                        border:
                          active && !isExpanded
                            ? `1px solid ${theme.palette.primary.solidActiveBg}`
                            : 'none',
                      }}
                    >
                      {showBadge && badge && essRatingData?.screening_available ? (
                        <Badge badgeInset={'20%'} color='primary'>
                          <Icon width={32} height={32} />
                        </Badge>
                      ) : (
                        <Icon width={32} height={32} />
                      )}
                    </IconButton>
                  </Grid>
                  <Grid
                    xs={10}
                    sx={{
                      pl: 1,
                      width: 'fit-content',
                    }}
                    justifyContent={'center'}
                    display={'flex'}
                    gap={0.5}
                    flexDirection={'column'}
                  >
                    {isExpanded && <FadingText isActive={active}>{title}</FadingText>}
                    {badge && essRatingData?.screening_available && isExpanded && (
                      <FadeChip size='sm' color='primary'>
                        {badge}
                      </FadeChip>
                    )}
                  </Grid>
                </StyledGrid>
              );
            })}
          </List>
        </Box>
      </Box>
    </>
  );
};
export default ProjectSidebar;
