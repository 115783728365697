import { Theme } from '@mui/joy';

import { brand } from '../../theme/brand';

export const getRiskAreaColorCode = (theme: Theme, isTile?: boolean) => ({
  critical: theme.palette['ESR']['critical'],
  high: theme.palette['ESR']['high'],
  moderate: theme.palette['ESR']['moderate'],
  low: theme.palette['ESR']['low'],
  insufficient_evidence: isTile
    ? theme.palette.mode === 'dark'
      ? brand.function.dark.neutral['80']
      : brand.function.light.neutral['20']
    : theme.palette['border']['border'],
  no_risks_identified: isTile
    ? theme.palette.mode === 'dark'
      ? brand.function.dark.neutral['80']
      : brand.function.light.neutral['20']
    : theme.palette['border']['border'],
});

export const getIntegrityColorCode = (theme: Theme) => {
  return {
    gold: theme.palette['ESR']['moderate'],
    silver: theme.palette['illustrations']['illustration_border_grey'],
    bronze: theme.palette.warning[500],
    fail: theme.palette['GHG']['E+'],
  };
};
