import { Stack, styled } from '@mui/joy';

export const StyledHTML = styled(Stack)(({ theme }) => ({
  ...theme.typography['body-md'],
  color: theme.vars.palette.text.primary,
  h1: { ...theme.typography.h4, marginBottom: theme.spacing(2) },
  h2: { ...theme.typography['sub1'], marginBottom: theme.spacing(2), marginTop: theme.spacing(2) },
  h3: {
    ...theme.typography['title-lg'],
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  p: {
    ...theme.typography['body-md'],
    color: theme.vars.palette.text.primary,
  },
  'p+p': { marginTop: theme.spacing(2) },
  a: {
    color: theme.vars.palette['hyperlink']['text'],
    textDecoration: 'none',
    textUnderlineOffset: '0.15em',
    '&:hover': { textDecoration: 'underline' },
  },
  u: {
    textDecoration: 'none',
  },
  ul: {
    ...theme.typography['body-md'],
    color: theme.vars.palette.text.primary,
  },
  ol: {
    ...theme.typography['body-md'],
    color: theme.vars.palette.text.primary,
  },
}));
