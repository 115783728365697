export const typography = {
  'body-lg': {
    fontSize: '18px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: '0px',
    lineHeight: '154.99999523162842%',
  },
  'body-md': {
    fontSize: '16px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 24,
    fontWeight: 400,
    letterSpacing: '0px',
    lineHeight: '28px',
  },
  'body-sm': {
    fontSize: '14px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 16,
    fontWeight: 400,
    letterSpacing: '0%',
    lineHeight: '20px',
  },
  'body-xs': {
    fontSize: '12px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: '0px',
    lineHeight: '165.9999966621399%',
  },
  h1: {
    fontSize: '40px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 700,
    letterSpacing: '0px',
    lineHeight: '133.00000429153442%',
  },
  h2: {
    fontSize: '36px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 24,
    fontWeight: 700,
    letterSpacing: '0px',
    lineHeight: '48px',
  },
  h3: {
    fontSize: '28px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 24,
    fontWeight: 400,
    letterSpacing: '0%',
    lineHeight: '40px',
  },
  h4: {
    fontSize: '24px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 700,
    letterSpacing: '0px',
    lineHeight: '32px',
  },
  sub1: {
    fontSize: '20px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 20,
    fontWeight: 700,
    letterSpacing: '0%',
    lineHeight: '28px',
  },
  'title-lg': {
    fontSize: '18px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: '0px',
    lineHeight: '165.9999966621399%',
  },
  'title-md': {
    fontSize: '16px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: '0px',
    lineHeight: '150%',
  },
  'title-sm': {
    fontSize: '14px',
    fontFamily: 'Lato, sans-serif',
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: '0px',
    lineHeight: '141.99999570846558%',
  },
};
