import React, { useEffect, useState } from 'react';

import { Button, Card, Stack, Typography } from '@mui/joy';
import { TbBulb } from 'react-icons/tb';

import { track } from '../../services/analytics';
import {
  EVENT_PROGRAM_SCREENING_PAGE,
  EVNT_request_program_screening,
  EVNT_request_program_screening_submitted,
  PGV_program_screening,
} from '../../services/analytics/events';

import { Approach } from './Approach';
import { ProgramTable } from './ProgramTable';
import RequestEsrScreening from './RequestEsrScreening';

const Programs: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    track(EVENT_PROGRAM_SCREENING_PAGE, PGV_program_screening);
  }, []);

  return (
    <Stack sx={{ px: { sm: 4, lg: 0 }, mx: 'auto', mt: '12px', mb: 5, maxWidth: '1032px' }} gap={3}>
      <Stack gap={1}>
        <Stack>
          <Typography level='h4' color='primary'>
            Programs
          </Typography>
          <Typography level='title-lg' color='primary'>
            Screening of carbon crediting programs
          </Typography>
        </Stack>
        <Typography level='body-md' color='primary'>
          As the first step in our three-step rating process, we screen the programs that issue
          carbon credits. In order for Calyx Global to rate carbon projects, the program issuing its
          credits must meet certain minimum criteria. This page summarizes the results of our
          program-level screenings and provides information on our approach for conducting these
          screenings.
        </Typography>
      </Stack>
      <ProgramTable />
      <Approach />
      <Card>
        <Typography level='h4'>Request an in-depth program screening</Typography>
        <Typography level='body-md' color='primary'>
          Comprehensive program screening reports are available for purchase. These reports include
          our evaluation of a program's performance against all 31 criteria in our 6 assessment
          areas.
        </Typography>
        <Button
          sx={{
            background: (theme) => theme.palette['linearGradient']['special'],
            width: 'fit-content',
          }}
          size='sm'
          startDecorator={<TbBulb />}
          onClick={() => {
            track(EVENT_PROGRAM_SCREENING_PAGE, EVNT_request_program_screening);
            setIsModalOpen(true);
          }}
        >
          Request a program screening
        </Button>
      </Card>
      <RequestEsrScreening
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        onSubmit={() => {
          track(EVENT_PROGRAM_SCREENING_PAGE, EVNT_request_program_screening_submitted);
        }}
      />
    </Stack>
  );
};

export { Programs };
