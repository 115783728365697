import { Box, Divider, Stack, styled, Typography, useTheme } from '@mui/joy';

import Image from '../../../../components/Image';
import useIsLargeScreen from '../../../../utils/hooks/useIsLargeScreen';

import { HTMLRenderer } from './HTMLRenderer';

const CustomImage = styled(Image)(() => ({
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  objectPosition: '50% 50%',
}));

interface PropTypes {
  src: string;
  alt: string;
  description: string;
}

const ImageContainer = ({ alt, description, src }: PropTypes) => {
  const isLg = useIsLargeScreen();
  const theme = useTheme();
  return (
    <Box
      display={'grid'}
      gridTemplateColumns={isLg ? '4fr 0fr 3fr' : '1fr'}
      width={'100%'}
      gap={isLg ? 6 : 2}
    >
      <Stack gap={1} position='relative'>
        <CustomImage
          src={src}
          alt={alt}
          style={{ backgroundColor: theme.palette.background.surface }}
          isZoomable
        />
      </Stack>
      {!!description ? (
        <>
          <Divider orientation='vertical' />
          <Typography
            level='body-md'
            color='neutral'
            sx={{ wordBreak: 'break-word', gridColumn: isLg ? '3 / 7' : 'auto' }}
          >
            <HTMLRenderer colorType='secondary' dangerouslySetInnerHTML={{ __html: description }} />
          </Typography>
        </>
      ) : null}
    </Box>
  );
};

export default ImageContainer;
