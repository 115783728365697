import { useEffect, useState } from 'react';

import { Box, Stack, Typography, useColorScheme } from '@mui/joy';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const getColorForSectionRating = (rating: number) => {
  let color = '#EE4520';
  switch (6 - rating) {
    case 1:
      color = '#EE4520';
      break;
    case 2:
      color = '#FF8F0C';
      break;
    case 3:
      color = '#FFDB1D';
      break;
    case 4:
      color = '#9EDA18';
      break;
    case 5:
      color = '#22C11F';
      break;
    case 7:
      color = '#22C11F';
      break;
  }
  return color;
};

interface PropTypes {
  rating: number;
  title?: string;
}

export const RatingSemicircle = ({ rating, title }: PropTypes) => {
  const { mode } = useColorScheme();
  const [ratingPercentage, setRatingPercentage] = useState<number>(0);

  useEffect(() => {
    setRatingPercentage(0);
    const timer = setTimeout(() => {
      if (rating === -1) {
        setRatingPercentage(100);
      } else {
        setRatingPercentage(rating === 0 ? 0 : ((6 - rating) / 5) * 100);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [rating]);

  let ratingText;
  if (rating === 0) {
    ratingText = 'Not assessed';
  } else if (rating === -1) {
    ratingText = 'No risk';
  }

  return (
    <Stack alignItems={'center'} spacing={2}>
      {!!title ? (
        <Typography fontSize={'md'} fontWeight={'xl'} color='primary'>
          {title}
        </Typography>
      ) : null}
      <Box height={'120px'}>
        <CircularProgressbarWithChildren
          value={ratingPercentage}
          circleRatio={0.5}
          strokeWidth={5}
          styles={buildStyles({
            rotation: 0.75,
            trailColor: mode === 'dark' ? '#171a1c' : '#f0f4f8',
            pathColor: getColorForSectionRating(rating),
          })}
        >
          <Box position={'relative'} top={'-16px'}>
            {rating === -1 || rating === 0 ? (
              <Typography component={'span'} fontSize={'xl'} fontWeight={900}>
                {ratingText}
              </Typography>
            ) : (
              <Typography fontSize={'xl2'} fontWeight={'xl'}>
                <Typography component={'span'} fontSize={'xl4'} fontWeight={900}>
                  {6 - rating}
                </Typography>
                /5
              </Typography>
            )}
          </Box>
        </CircularProgressbarWithChildren>
      </Box>
    </Stack>
  );
};
