import { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/joy';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import ReactEcharts from 'echarts-for-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { track } from '../../../../services/analytics';
import {
  EVENT_EXECUTIVE_SUMMARY_PAGE,
  EVENT_PROJECT_COMPARISON_PAGE,
  EVNTAPP_project_comparison_all_type_click,
  EVNTAPP_project_comparison_click,
  EVNTAPP_project_comparison_this_type_click,
} from '../../../../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../../../../services/axios';
import { projectGhgSdg } from '../../../../services/axios/endpoints';
import { projectState } from '../../../../stores/project';
import { APPLIED_FILTERS_URL_KEY } from '../../../search/Search';
import { getGhgSdgGraphData } from '../../constants';

const ProjectComparison = ({ hasToggle }: { hasToggle?: boolean }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { projectData } = useRecoilValue(projectState);
  const { search, pathname } = useLocation();
  const setProjectState = useSetRecoilState(projectState);
  const [params] = useSearchParams();
  const [projectComparisonType, setProjecComparisonType] = useState<'this_project' | 'all_project'>(
    'all_project'
  );

  const ghg_standard_code = params.get('standard');
  const crediting_period_id = params.get('cp_no');
  const project_id = params.get('id');
  const { data, isLoading, isFetched, isRefetching } = useQuery({
    queryKey: [`project-sdg-ghg-${projectComparisonType}`, crediting_period_id],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectGhgSdg, {
        params: {
          project_id,
          crediting_period_id,
          ghg_standard_code,
          filter: projectComparisonType,
        },
      }),
    select: (data) => data?.data.graph,
  });
  const noData = useMemo(
    () =>
      isFetched &&
      !isLoading &&
      !isRefetching &&
      (!data || (data?.allProjectRatingData || []).length === 0),
    [data, isFetched, isLoading, isRefetching]
  );

  useEffect(() => {
    if (isFetched)
      setProjectState((value) => ({
        ...value,
        projectComparisonData: data,
      }));
  }, [data, isFetched, setProjectState]);

  const options = useMemo(() => {
    const x = [0, 1, 2, 3, 4, 5, 6, 7];
    const y = [0, 1, 2, 3, 4, 5, 6];
    if (data) {
      return {
        backgroundColor: 'transparent',
        legend: {
          data: [{ name: 'This project' }, { name: 'Other projects' }],
          bottom: 0, // Adjust the value as needed
          left: 10, // Adjust the value as needed
          icon: 'circle', // Use circle for rounded icons
          textStyle: {
            color: theme.palette.text.primary,
            fontSize: '12px',
            fontFamily: "'Inter Variable', sans-serif",
          },
        },
        tooltip: {
          position: 'top',
          trigger: 'item',
          formatter: function (params) {
            return params.name;
          },
        },
        grid: {
          containLabel: false,
        },
        xAxis: {
          name: 'GHG integrity rating',
          nameLocation: 'middle',
          nameGap: 30,
          nameTextStyle: {
            color: theme.palette.text.primary,
          },
          type: 'value',
          data: x,
          min: 0,
          max: 8,
          splitLine: {
            show: false,
            lineStyle: {
              // Dark and light colors will be used in turns
              color: '#B3B3B3',
              type: 'dashed',
            },
          },
          axisLine: {
            show: true,
            // onzero: true,
            lineStyle: {
              // Dark and light colors will be used in turns
              color: '#B3B3B3',
              type: 'dashed',
              opacity: 0.2,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            interval: 0,
            overflow: 'breakAll',
            position: 'bottom',
            fontSize: 11,
            fontWeight: 500,
            fontFamily: 'lato',
            hideOverlap: true,
            align: 'left',
            padding: [0, 0, 0, 0],
            color: theme.palette.text.primary,
            formatter: function (_, index) {
              if (index === 0) {
                return 'Low';
              } else if (index === 4) {
                return 'High';
              }
              return '';
            },
            textStyle: {
              fontFamily: "'Inter Variable', sans-serif",
            },
          },
        },
        yAxis: [
          {
            name: 'SDG impact score',
            nameLocation: 'middle',
            nameGap: 25,
            nameTextStyle: {
              color: theme.palette.text.primary,
            },
            type: 'value',
            data: y,
            min: 0,
            max: 6,
            // onzero: true,
            //  boundaryGap: true,
            splitLine: {
              show: false,
              lineStyle: {
                // Dark and light colors will be used in turns
                color: ['#aaa'],
                type: 'dashed',
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // Dark and light colors will be used in turns
                color: '#B3B3B3',
                type: 'dashed',
                opacity: 0.2,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              name: 'Y-Axis',
              overflow: 'breakAll',
              interval: 0,
              position: 'top',
              rotate: 90,
              fontSize: 11,
              fontWeight: 500,
              fontFamily: 'lato',
              hideOverlap: true,
              align: 'left',
              color: theme.palette.text.primary,
              //  margin: 8,

              //padding: [4, 14, 15, 10],
              formatter: function (_, index) {
                if (index === 0) {
                  return 'None';
                } else if (index === 2) {
                  return 'Low';
                } else if (index === 5) {
                  return 'High';
                }
                return '';
              },
              textStyle: {
                fontFamily: "'Inter Variable', sans-serif",
              },
            },
          },
        ],
        series: getGhgSdgGraphData(data),
      };
    }
    return {};
  }, [data, theme]);

  const onChartClick = (params) => {
    if (params.componentType !== 'series') return;
    navigate(
      `/search?${new URLSearchParams({
        [APPLIED_FILTERS_URL_KEY]: encodeURIComponent(
          JSON.stringify({
            ghgRatingNumber: {
              values: [`${8 - params.value?.[0] + 0.5}`],
              filterType: 'set',
            },
            sdgRatingNumber: {
              values:
                params.value?.[1] === 0.5
                  ? [`${params.value?.[1] - 0.5}`, '-1']
                  : [`${params.value?.[1] - 0.5}`],
              filterType: 'set',
            },
            ...(projectComparisonType === 'this_project'
              ? {
                  projectType: {
                    values: [`${projectData?.projectType}`],
                    filterType: 'set',
                  },
                }
              : {}),
          })
        ),
      })}`
    );
  };

  const onEvents = {
    click: onChartClick,
  };

  if (noData) return null;

  const handleProjectComparison = () => {
    !isLoading && !isRefetching && navigate({ pathname: '/project_comparison', search });
    track(EVNTAPP_project_comparison_click, EVENT_EXECUTIVE_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      page_url: `${pathname}${search}`,
    });
  };

  const handleProjectComparisonType = (type: 'all_project' | 'this_project') => {
    track(
      type === 'all_project'
        ? EVNTAPP_project_comparison_all_type_click
        : EVNTAPP_project_comparison_this_type_click,
      EVENT_PROJECT_COMPARISON_PAGE,
      {
        project_id: projectData?.projectId,
        page_url: `${pathname}${search}`,
      }
    );
    setProjecComparisonType(type);
  };

  return (
    <Card sx={{ mb: hasToggle ? 0 : 3, gap: 0, p: (theme) => theme.spacing(3), flex: 1 }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography fontSize={20} fontWeight={'xl'} color='primary' mb={2}>
          Project Comparison
        </Typography>
        {hasToggle ? (
          <ButtonGroup size='sm' sx={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={() => handleProjectComparisonType('all_project')}
              variant={projectComparisonType === 'all_project' ? 'solid' : 'outlined'}
              color='primary'
            >
              All project types
            </Button>
            <Button
              onClick={() => handleProjectComparisonType('this_project')}
              variant={projectComparisonType === 'this_project' ? 'solid' : 'outlined'}
              color='primary'
            >
              This project type
            </Button>
          </ButtonGroup>
        ) : (
          <IconButton variant='outlined' onClick={handleProjectComparison}>
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
      {!hasToggle && <Divider sx={{ width: 1, mx: 'auto', mt: (theme) => theme.spacing(2) }} />}
      <Box sx={{ position: 'relative', height: '400px' }}>
        {isLoading || isRefetching ? (
          <Skeleton height={400} />
        ) : (
          <ReactEcharts
            option={options}
            style={{ height: '400px', width: '100%' }}
            onEvents={onEvents}
          />
        )}
      </Box>
    </Card>
  );
};

export default ProjectComparison;
