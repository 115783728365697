import { Box, Button, Card, Stack, Typography, TypographyProps, useColorScheme } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';
import CalyxLogo from 'jsx:../assets/icons/logo.svg';
import CalyxLogoDark from 'jsx:../assets/icons/logoDark.svg';
import { IconBaseProps, IconType } from 'react-icons';
import { MdFindInPage } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

type MessageProps = {
  title: string;
  description: string;
  Icon?: IconType;
  hasAction?: boolean;
  action?: () => void;
  actionText?: string;
  iconProps?: IconBaseProps;
  headingProps?: TypographyProps;
  descriptionProps?: TypographyProps;
  hasHeading?: boolean;
} & SxProps;
const MessagePage: React.FC<MessageProps> = ({
  title,
  description,
  Icon,
  hasAction,
  actionText,
  action,
  headingProps,
  iconProps,
  descriptionProps,
  hasHeading = true,
  ...props
}) => {
  const navigate = useNavigate();
  const { mode } = useColorScheme();

  const handleAction = () => {
    navigate(
      {
        pathname: '/',
      },
      { replace: true }
    );
  };

  const content = (
    <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} m={4} gap={2}>
      {Icon ? <Icon size={82} {...iconProps} /> : <MdFindInPage size={82} {...iconProps} />}
      <Typography level='h2' {...headingProps}>
        {title}
      </Typography>
      <Typography level='body-lg' {...descriptionProps}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </Typography>
      {(hasAction || action) && (
        <Button onClick={action ? action : handleAction} sx={{ width: 'fit-content' }}>
          {actionText || 'Return to the homepage'}
        </Button>
      )}
    </Stack>
  );

  return (
    <Stack gap={4} height={1} sx={{ ...props }}>
      {hasHeading && (
        <Card
          sx={{
            background: (theme) => theme.palette.background.body,
            width: '100%',
            borderRadius: 0,
          }}
        >
          <Box sx={{ width: '150px', height: '50px', mx: 'auto' }}>
            {mode === 'dark' ? (
              <CalyxLogoDark width={164} height={36} />
            ) : (
              <CalyxLogo width={164} height={36} />
            )}
          </Box>
        </Card>
      )}
      {hasHeading ? (
        <Card
          sx={{
            height: 'calc(100vh - 140px)',
          }}
        >
          {content}
        </Card>
      ) : (
        content
      )}
    </Stack>
  );
};

export default MessagePage;
