export const brand = {
  danger: {
    '100': '#fce4e4',
    '200': '#f7c5c5',
    '300': '#f09898',
    '400': '#e47474',
    '500': '#c41c1c',
    '600': '#a51818',
    '700': '#7d1212',
    '800': '#430a0a',
    '900': '#240505',
    '050': '#fef6f6',
  },
  neutral: {
    '100': '#f9fafb',
    '200': '#dde7ee',
    '300': '#cdd7e1',
    '400': '#9fa6ad',
    '500': '#636b74',
    '600': '#555e68',
    '700': '#32383e',
    '800': '#171a1c',
    '900': '#0b0d0e',
    '050': '#fbfcfe',
  },
  ocean: {
    '100': '#e3effb',
    '200': '#c7dff7',
    '300': '#97c3f0',
    '400': '#4393e4',
    '500': '#0b6bcb',
    '600': '#185ea5',
    '700': '#12467b',
    '800': '#0a2744',
    '900': '#051423',
    '050': '#edf5fd',
  },
  primary: {
    light: {
      '100': '#f5fbf4',
      '200': '#e8f7e7',
      '300': '#d9f1d7',
      '400': '#c7eac4',
      '500': '#b1dfad',
      '600': '#92d08e',
      '700': '#64bb61',
      '800': '#1f7a1f',
      '900': '#056b0a',
      '1000': '#268025',
      '1100': '#203d1e',
      '050': '#fbfefa',
    },
    dark: {
      '100': '#121b12',
      '200': '#172b16',
      '300': '#153c13',
      '400': '#194b18',
      '500': '#1e5b1d',
      '600': '#236c22',
      '700': '#257f24',
      '800': '#1f7a1f',
      '900': '#20691f',
      '1000': '#79d074',
      '1100': '#b1f7ad',
      '050': '#0b140b',
    },
  },
  warning: {
    '100': '#fdf0e1',
    '200': '#fce1c2',
    '300': '#f3c896',
    '400': '#ea9a3e',
    '500': '#9a5b13',
    '600': '#72430d',
    '700': '#492b08',
    '800': '#2e1b05',
    '900': '#1d1002',
    '050': '#fefaf6',
  },
  common: { white: '#ffffff', black: '#000000' },
  function: {
    light: {
      green: {
        '100': '#1fa21c',
        '90': '#35ab33',
        '80': '#4cb549',
        '70': '#62be60',
        '60': '#79c777',
      },
      lime: {
        '100': '#86bd09',
        '90': '#92c422',
        '80': '#9eca3a',
        '70': '#aad153',
        '60': '#b6d76c',
      },
      yellow: {
        '100': '#ebc606',
        '90': '#edcc1f',
        '80': '#efd138',
        '70': '#f1d751',
        '60': '#f3dd6a',
      },
      orange: {
        '100': '#ff8310',
        '90': '#ff8f28',
        '80': '#ff9b3f',
        '70': '#ffa857',
        '60': '#ffb46f',
      },
      red: { '100': '#ef5928', '90': '#f16a3d', '80': '#f27a53', '70': '#f48b68', '60': '#f59b7e' },
      neutral: {
        '100': '#000000',
        '90': '#1a1a1a',
        '80': '#333333',
        '70': '#4d4d4d',
        '60': '#666666',
        '50': '#808080',
        '40': '#999999',
        '30': '#b3b3b3',
        '20': '#cccccc',
        '10': '#f9fafb',
      },
      fuel: {
        '100': '#bb7bb5',
        '90': '#c288bc',
        '80': '#c995c3',
        '70': '#cfa2cb',
        '60': '#d6b0d2',
      },
      ocean: '#4b6399',
      'tennis-green': '#BDDB42',
    },
    dark: {
      green: {
        '100': '#22c11f',
        '90': '#1fae1c',
        '80': '#1b9b18',
        '70': '#188716',
        '60': '#157412',
      },
      lime: {
        '100': '#9eda18',
        '90': '#8ec416',
        '80': '#7eae13',
        '70': '#6f9911',
        '60': '#5f830e',
      },
      yellow: {
        '100': '#ffdb1d',
        '90': '#e5c51a',
        '80': '#ccaf17',
        '70': '#b29914',
        '60': '#998311',
      },
      orange: {
        '100': '#ff8f0c',
        '90': '#e5810b',
        '80': '#cc730a',
        '70': '#b26409',
        '60': '#995607',
      },
      red: {
        '100': '#ee4520',
        '90': '#d63e1d',
        '80': '#be3719',
        '70': '#a73016',
        '60': '#8f2913',
      },
      neutral: {
        '100': '#ffffff',
        '90': '#e5e5e5',
        '80': '#cccccc',
        '70': '#b3b3b3',
        '60': '#999999',
        '50': '#808080',
        '40': '#666666',
        '30': '#424242',
        '20': '#333333',
        '10': '#1a1a1a',
      },
      fuel: {
        '100': '#ba51eb',
        '90': '#a749d3',
        '80': '#9541bc',
        '70': '#8239a4',
        '60': '#3d1a4d',
      },
      ocean: '#3f57d4',
    },
  },
  base: {
    light: {
      forest: { '90': '#2a892d', '80': '#419645', '70': '#59a35c', '60': '#71b073' },
    },
    dark: {
      forest: {
        '100': '#1d9922',
        '90': '#1a8a1e',
        '80': '#177a1b',
        '70': '#146b18',
        '60': '#115c14',
      },
    },
  },
  sky: {
    dark: {
      '100': '#00cde5',
      '200': '#00b6cc',
      '300': '#00a0b2',
      '400': '#008999',
      '050': '#00e4ff',
    },
    light: {
      '100': '#5db6c2',
      '200': '#46abb9',
      '300': '#2ea1b1',
      '400': '#1797a8',
      '050': '#74c0cb',
    },
  },
  expert: {
    light: { '100': '#8239a4', '050': '#a749d3' },
    dark: { '100': '#3d1a4d', '050': '#8239a4' },
  },
  blur: { light: '#ededed', dark: '#393939' },
  linearGradient: {
    light: 'linear-gradient(270deg, #8239A4 0%, #A749D3 100%)',
    dark: 'linear-gradient(270deg, #3D1A4D 0%, #8239A4 100%)',
  },
  hyperlink: {
    cell: {
      light: '#eaf3fd',
      dark: '#162126',
    },
    text: {
      light: '#3874cb',
      dark: '#9cc8f5',
    },
    underline: {
      light: '#b0c8ea',
      dark: '#495b6e',
    },
  },
  filter: {
    light: '#ededed',
    dark: '#393939',
  },
};
