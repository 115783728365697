import { useState } from 'react';

import { Button, Card, Grid, Link, Stack, Typography, TypographySystem, useTheme } from '@mui/joy';
import { IoMdCloseCircle } from 'react-icons/io';
import { TbBulb } from 'react-icons/tb';
import { useRecoilValue } from 'recoil';

import { track } from '../../../../../services/analytics';
import {
  EVENT_ESR_RATING_PAGE,
  EVNT_request_esr_screening,
  EVNT_request_esr_screening_submitted,
  EVNT_view_sample_esr_report,
} from '../../../../../services/analytics/events';
import { assetBaseUrl } from '../../../../../services/axios/endpoints';
import { projectState } from '../../../../../stores/project';
import { screeningIconMap } from '../../../constants';
import RequestEsrReport from '../RequestEsrReport';
import RiskAreaComparison from '../RiskAreaComparison';
import { StyledHTML } from '../StyledHTML';

const NoScreening = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { projectData } = useRecoilValue(projectState);
  const theme = useTheme();

  return (
    <Stack gap={3}>
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        <IoMdCloseCircle size={32} />
        <Typography level='h4'>Project-specific risks have not been screened</Typography>
      </Stack>
      <Typography level='body-md' color='primary'>
        No screening has been done to assess risks on the {projectData?.title} project. Risks
        associated with a project can be influenced by factors such as location, local regulations
        and the developer's approach to sustainability and social responsibility. For example, if
        the project is situated in a naturally protected zone, inhabited by indigenous peoples or
        affected by local conflicts, these factors can amplify the inherent risks of the project.
        While the information on inherent risks below provides a broad understanding of the aspects
        to be considered in each type of project, project-specific analysis also considers
        contextual risks to help you compare projects of the same type.
      </Typography>
      <Typography level='h4'>{data?.title}</Typography>
      <StyledHTML
        fontWeight={'md'}
        fontSize={theme.vars.fontSize.lg}
        color='primary'
        dangerouslySetInnerHTML={{ __html: data?.content }}
      />
      <Typography
        level={'sub1' as keyof TypographySystem}
        sx={{ color: (theme) => theme.palette.text.primary }}
      >
        Environmental and social risks inherent in {projectData?.projectType} projects are commonly
        found in the following areas :{' '}
      </Typography>
      <Stack direction={'row'} gap={3} flexWrap={'wrap'}>
        {(data?.area_covered_relation || []).map((area) => (
          <Card
            variant='outlined'
            sx={{
              width: '23%',
              minWidth: '207.5px',
              maxWidth: 400,
              minHeight: '56px',
              maxHeight: 'px',
              height: 'auto',
              p: 1,
              background: (theme) => theme.palette.background.surface,
              borderWidth: '2px',
            }}
          >
            <Grid container gap={2} sx={{ flexGrow: 1, flexWrap: 'nowrap' }}>
              <Grid alignItems='center' display={'flex'}>
                {screeningIconMap[area.area_covered.name].icon({ width: 32, height: 32 }) || ''}
              </Grid>
              <Grid alignItems='center' display={'flex'} sx={{ width: '80%' }}>
                <Typography
                  fontSize={'sm'}
                  lineHeight={'18.62px'}
                  fontWeight={'md'}
                  color='primary'
                >
                  {area.area_covered.display_name}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        ))}
      </Stack>
      {(data?.projectComparison?.riskLevelCounts || []).length > 0 && (
        <>
          <Typography
            level={'sub1' as keyof TypographySystem}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            Environmental and social risk distribution for all {projectData?.projectType} projects
          </Typography>
          <Typography level='body-md' color='primary'>
            The following histograms show risk level ratings distributions from the{' '}
            {data?.projectCount} {projectData?.projectType} projects that have been screened for ESR
            by Calyx Global. Safeguard areas are grouped into social, governance, and environmental
            risks. The highest risk level from each category is represented on the graphs. No
            project is entirely without risk.
          </Typography>
          <RiskAreaComparison
            data={data?.projectComparison?.riskLevelCounts || []}
            showMarkPoint={false}
          />
        </>
      )}
      <Typography
        level={'sub1' as keyof TypographySystem}
        sx={{ color: (theme) => theme.palette.text.primary }}
      >
        Inherent risks
      </Typography>
      <StyledHTML
        fontSize={theme.vars.fontSize.lg}
        fontWeight={'md'}
        color='primary'
        dangerouslySetInnerHTML={{ __html: data?.content_after_icons }}
      />
      <Card>
        <Typography level='h4'>Request an ESR screening</Typography>
        <Typography level='body-md' color='primary'>
          The Calyx Global Safeguards Team conducts project-specific ESR screenings, which includes
          a comprehensive desk review of key documentation and interviews with the project developer
          and/or affected stakeholders where possible.
        </Typography>

        <Link
          href={`${assetBaseUrl}/public/pdf/VCS2600+Oeste+de+Caucaia+Landfill+Project_ESR+sample+report.pdf`}
          target='_blank'
          onClick={() => {
            track(EVNT_view_sample_esr_report, EVENT_ESR_RATING_PAGE, {
              project_id: projectData?.projectId,
              project_name: projectData?.title,
            });
          }}
        >
          Download an example of an ESR screening for VCS2600 Project
        </Link>

        <Button
          sx={{
            background: (theme) => theme.palette['linearGradient']['special'],
            width: 'fit-content',
          }}
          size='sm'
          startDecorator={<TbBulb />}
          onClick={() => {
            track(EVNT_request_esr_screening, EVENT_ESR_RATING_PAGE, {
              project_id: projectData?.projectId,
              project_name: projectData?.title,
            });
            setIsModalOpen(true);
          }}
        >
          Request an ESR screening
        </Button>
      </Card>
      <RequestEsrReport
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        onSubmit={() => {
          track(EVNT_request_esr_screening_submitted, EVENT_ESR_RATING_PAGE, {
            project_id: projectData?.projectId,
            project_name: projectData?.title,
          });
        }}
      />
    </Stack>
  );
};

export default NoScreening;
