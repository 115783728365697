import { styled, Table } from '@mui/joy';

import { getRiskAreaColorCode } from '../../../utils/constants/colorCode';

export const StyledTD = styled('td')<{ riskLevel: string }>(({ theme, riskLevel }) => ({
  borderRightWidth: `${theme.spacing(1)} !important`,
  borderRightStyle: 'solid',
  borderRightColor: `${getRiskAreaColorCode(theme, true)[riskLevel]} !important`,
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  '--TableCell-cornerRadius': theme.spacing(1),
  '--TableCell-borderColor': theme.palette.primary.outlinedBorder,
  '--TableCell-headBackground': theme.palette.background.body,
  '--TableCell-footBackground': theme.palette.background.body,
  '--Table-headerUnderlineThickness': '3px',
  '--TableCell-paddingY': '6px',
  '--TableCell-paddingX': theme.spacing(1),
  th: {
    color: theme.palette.text.primary,
    fontWeight: theme.vars.fontWeight.xl,
    fontSize: theme.vars.fontSize.md,
    whiteSpace: 'normal',
    verticalAlign: 'middle !important',
  },
  td: {
    color: theme.palette.text.primary,
    fontWeight: theme.vars.fontWeight.md,
    fontSize: theme.vars.fontSize.md,
  },
}));
