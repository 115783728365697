export const setLocalStorageObject = (key: string, object: any) => {
  if (object instanceof Map) {
    const mapAsArray = Array.from(object.entries()); // Convert Map to an array of key-value pairs
    localStorage.setItem(key, JSON.stringify({ isMap: true, data: mapAsArray }));
  } else {
    localStorage.setItem(key, JSON.stringify({ isMap: false, data: object }));
  }
};

export const getLocalStorageObject = (key: string) => {
  const item = localStorage.getItem(key);
  if (!item) return null;

  try {
    const parsed = JSON.parse(item);
    if (parsed.isMap) {
      return new Map(parsed.data); // Convert array back to Map
    } else {
      return parsed.data;
    }
  } catch (error) {
    console.error('Error parsing localStorage item:', error);
    return null;
  }
};
