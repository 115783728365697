import { Theme } from '@mui/joy';

export const createTooltipHTML = (title, items, theme: Theme) => {
  const itemHTML = items
    .map(
      (item, index) => `
        <div style="padding: 8px 16px; ${index === items.length - 1 ? '' : `border-bottom: 1px solid ${theme.palette.primary.outlinedBorder};`} cursor: pointer;" 
             onmouseover="this.style.backgroundColor='${theme.palette['hyperlink']['cell']}'" 
             onmouseout="this.style.backgroundColor='transparent'"
             onclick="window.location.href='${item.link}'">
          <div style="font-weight: ${theme.vars.fontWeight.md}; font-size: ${theme.vars.fontSize.md}; color: ${theme.palette.text.primary}; text-wrap: wrap;">${item.name}</div>
          <div style="display: flex; flex-direction: row; align-items: center; gap: ${theme.spacing(1)};">
            <div style="font-weight: ${theme.vars.fontWeight.md}; font-size: ${theme.vars.fontSize.md}; color: ${theme.palette.text.tertiary};">${item.code}</div>
            <div style="border-left:1px solid ${theme.palette['divider']}; height: 15px; width: 1px;"></div>
            <div style="font-weight: ${theme.vars.fontWeight.md}; font-size: ${theme.vars.fontSize.md}; color: ${theme.palette.text.tertiary};">${item.duration}</div>
          </div>
        </div>`
    )
    .join('');

  const emptyMessage = `
      <div style="padding: 8px 16px; cursor: pointer;">
        <div style="font-weight: ${theme.vars.fontWeight.md}; font-size: ${theme.vars.fontSize.md}; color: ${theme.palette.text.primary}; text-wrap: wrap;">No projects found</div>
      </div>
    `;

  return `
      <div style="background: ${theme.palette.background.surface}; width: 400px;">
        <div style="background: ${theme.palette.background.body}; border-bottom: 1px solid ${theme.palette.primary.outlinedBorder}; padding: 8px 16px;">
          <div style="font-weight: ${theme.vars.fontWeight.lg}; font-size: ${theme.vars.fontSize.md}; color: ${theme.palette.text.primary}; text-wrap: wrap;">${title}</div>
        </div>
        <div style="max-height: 300px; overflow-y: auto; overflow-x: hidden;">${items.length === 0 ? emptyMessage : itemHTML}</div>
      </div>
    `;
};
