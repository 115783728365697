import { useState, useCallback } from 'react';

const MIN_SCALE = 1;
const MAX_SCALE = 2; // Hard-coded maximum zoom level

export const useZoomAndPan = () => {
  const [scale, setScale] = useState(MAX_SCALE);
  const [isScaled, setIsScaled] = useState(true);

  const toggleZoom = useCallback(() => {
    setScale((prevScale) => {
      const newScale = prevScale > MIN_SCALE ? MIN_SCALE : MAX_SCALE;
      setIsScaled(newScale > MIN_SCALE);
      return newScale;
    });
  }, []);

  const resetZoom = useCallback(() => {
    setScale(MAX_SCALE);
    setIsScaled(true);
  }, []);

  return {
    scale,
    isScaled,
    toggleZoom,
    resetZoom,
    maxScale: MAX_SCALE,
  };
};
