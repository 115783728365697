import { ImgHTMLAttributes, forwardRef, useState } from 'react';

import { Button } from '@mui/joy';
import { ImEnlarge } from 'react-icons/im';

import fallback from '../assets/images/fallback.png';

import ImageViewer from './ImageViewer';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt?: string;
  fallbackImage?: string;
  backgroundColor?: string;
  isZoomable?: boolean;
  zoomableText?: string;
}

const CommonImage = forwardRef<HTMLImageElement, ImageProps>(
  ({ src, alt, fallbackImage, isZoomable, zoomableText, ...rest }, ref) => {
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const target = e.target as HTMLImageElement;
      target.src = fallbackImage || fallback; // Set src to empty string to prevent broken image display
    };
    return (
      <>
        <img ref={ref} src={src} alt={alt} {...rest} onError={onError} />
        {isZoomable && (
          <>
            <Button
              variant='outlined'
              onClick={() => setIsViewerOpen(true)}
              startDecorator={<ImEnlarge />}
              sx={{
                ml: 'auto',
                display: 'flex',
              }}
            >
              {zoomableText || 'Enlarge'}
            </Button>
            <ImageViewer
              src={src}
              alt={alt || ''}
              onClose={() => setIsViewerOpen(false)}
              open={isViewerOpen}
              {...rest}
            />
          </>
        )}
      </>
    );
  }
);

export default CommonImage;
