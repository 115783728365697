import { useMemo } from 'react';

import { Card, Grid, Link, Stack, Typography, TypographySystem } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import { track } from '../../../../../services/analytics';
import {
  EVENT_ESR_RATING_PAGE,
  EVNT_esr_see_methodology_details_clicked,
} from '../../../../../services/analytics/events';
import { projectState } from '../../../../../stores/project';
import { screeningIconMap } from '../../../constants';
import { StyledHTML } from '../StyledHTML';

const SourcesAndMethodology = ({ data }) => {
  const { projectData } = useRecoilValue(projectState);
  const riskAreas = useMemo(() => data?.noScreeningData?.area_covered_relation || [], [data]);
  return (
    <Stack gap={2}>
      <StyledHTML
        fontWeight={'md'}
        fontSize={'lg'}
        color='primary'
        dangerouslySetInnerHTML={{ __html: data?.description }}
      />
      <Typography level='h4'>Methodology</Typography>
      <Typography fontSize={'md'} fontWeight={'md'} color='primary'>
        Environmental and social risk screenings are carried out by our Calyx Global Safeguards team
        through a comprehensive desk review of key documentation. For certain project types and
        specific projects, desk review screening is further complemented and enhanced by information
        gathered through interviews with project-affected stakeholders. To learn more,{' '}
        <Link
          href='https://app.calyxglobal.com/blog-detail?tag=202'
          target='_blank'
          onClick={() => {
            track(EVNT_esr_see_methodology_details_clicked, EVENT_ESR_RATING_PAGE, {
              project_id: projectData?.projectId,
              project_name: projectData?.title,
            });
          }}
        >
          read about the details of our approach here.
        </Link>
      </Typography>
      {data?.noScreeningData && (
        <>
          <Typography level={'sub1' as keyof TypographySystem}>
            {data?.noScreeningData?.title}
          </Typography>
          <StyledHTML
            fontSize={'md'}
            fontWeight={'md'}
            color='primary'
            dangerouslySetInnerHTML={{ __html: data?.noScreeningData?.content }}
          />
        </>
      )}
      {riskAreas.length > 0 && (
        <>
          <Typography level='title-lg'>
            Environmental and social risks inherent in {projectData?.projectType} projects are
            commonly found in the following{' '}
            <Typography component={'span'} sx={{ whiteSpace: 'nowrap' }}>
              areas :
            </Typography>
          </Typography>
          <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
            {riskAreas.map((area) => (
              <Card
                variant='outlined'
                sx={{
                  width: '23%',
                  minWidth: '207.5px',
                  maxWidth: 400,
                  minHeight: '56px',
                  maxHeight: 'px',
                  height: 'auto',
                  p: 1,
                  background: (theme) => theme.palette.background.surface,
                  borderWidth: '2px',
                }}
              >
                <Grid container spacing={2} sx={{ flexGrow: 1, flexWrap: 'nowrap' }}>
                  <Grid alignItems='center' display={'flex'}>
                    {screeningIconMap[area?.area_covered?.name]?.icon({ width: 32, height: 32 }) ||
                      ''}
                  </Grid>
                  <Grid alignItems='center' display={'flex'} sx={{ pl: '1rem', width: '80%' }}>
                    <Typography
                      fontSize={'sm'}
                      lineHeight={'18.62px'}
                      fontWeight={'md'}
                      color='primary'
                    >
                      {area?.area_covered?.display_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default SourcesAndMethodology;
