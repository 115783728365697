import { getProjectTypeColor } from '../../pages/home/constants';
import { BreakdownByTypes } from '../../pages/retireeDrillDown/components/breakdownCharts/BreakdownCharts';
import { getGHGratingColor, getSDGRatingColorV2 } from '../../pages/search/constants';
import { colors } from '../constants/colors';

export const addBarBorderRadius = (optionParam: any) => {
  const option = { ...optionParam };
  for (let dataIndex = 0, moreValue = true; moreValue; dataIndex++) {
    moreValue = false;
    for (let i = option?.series?.length - 1; i >= 0; i--) {
      const data = option.series[i].data;
      if (dataIndex < data.length) {
        moreValue = true;
        const val = data[dataIndex];
        if (!isNaN(val) && val != null) {
          data[dataIndex] = {
            value: val,
            itemStyle: {
              barBorderRadius: [4, 4, 0, 0],
            },
          };
          break;
        }
      }
    }
  }
  return option;
};

export const getChartDataItemColor = (
  breakdownType: BreakdownByTypes,
  key: string,
  isDarkMode: boolean,
  index: number
) => {
  switch (breakdownType) {
    case 'project_category':
      return key === 'Unknown' || key === 'Not Rated'
        ? '#999'
        : getProjectTypeColor(key, isDarkMode) ?? colors[index];
    case 'ghg_rating':
      return key === 'Unknown' || key === 'Not Rated'
        ? '#999'
        : getGHGratingColor(key, isDarkMode) ?? colors[index];
    case 'sdg_rating':
      return key === 'Unknown' || key === 'Not Rated'
        ? '#999'
        : getSDGRatingColorV2(key, isDarkMode) ?? colors[index];
    default:
      return key === 'Unknown' || key === 'Not Rated' ? '#999' : colors[index];
  }
};

export const sortChartItems = (shouldOrderByLabel: boolean, breakdown_type: BreakdownByTypes) => {
  return (a, b) => {
    if (shouldOrderByLabel) {
      if (breakdown_type === 'sdg_rating') {
        return Number(b?.name?.split('+')?.[1]) - Number(a?.name?.split('+')?.[1]);
      }
      if (breakdown_type === 'ghg_rating') {
        const val1 = a?.name?.split('+');
        const val2 = b?.name?.split('+');
        const baseOrder = val1[0].localeCompare(val2[0]);
        if (baseOrder === 0) {
          return val2.length - val1.length;
        }
        return baseOrder;
      }
      return b?.name - a?.name;
    } else {
      return Number(b?.value) - Number(a?.value);
    }
  };
};
