import { Skeleton, Stack } from '@mui/joy';

const RiskSummaryLoader = () => {
  return (
    <Stack gap={3}>
      <Stack gap={2}>
        <Skeleton variant='text' />
        <Skeleton variant='text' />
        <Skeleton variant='text' />
      </Stack>
      <Skeleton variant='rectangular' sx={{ height: (theme) => theme.spacing(4) }} />
      <Stack gap={3}>
        {[...new Array(3).fill(null)].map((_, index) => (
          <Stack direction={'row'} gap={2} alignItems={'center'} key={index}>
            <Skeleton variant='text' sx={{ width: (theme) => theme.spacing(80) }} />
            <Skeleton variant='rectangular' sx={{ height: (theme) => theme.spacing(8) }} />
            <Skeleton variant='rectangular' sx={{ height: (theme) => theme.spacing(8) }} />
            <Skeleton variant='rectangular' sx={{ height: (theme) => theme.spacing(8) }} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default RiskSummaryLoader;
