import { useCallback, useState } from 'react';

import { useTheme } from '@mui/joy';

import { capitalizeFirstLetter } from '../../../../../utils/functions/string';
import { StyledTable, StyledTD } from '../../styles';

const RiskSummaryTable = ({ data: risks, scrollReadMoreIntoView }) => {
  const [hover, setHover] = useState({ area: '', category: '' });
  const theme = useTheme();
  const getTotalLength = useCallback((areas: any[]) => {
    return (areas || [])?.reduce((total, risksArea) => total + (risksArea.risks.length || 1), 0);
  }, []);

  return (
    <StyledTable borderAxis='both'>
      <thead>
        <tr>
          <th style={{ width: '15%' }}>Safeguard category</th>
          <th style={{ width: '25%' }}>Safeguard area</th>
          <th style={{ width: '45%' }}>Risk</th>
          <th style={{ width: '15%' }}>Risk level</th>
        </tr>
      </thead>
      <tbody>
        {risks.map((risk, riskIndex) => (
          <>
            {risk.areas.map((area, areaIndex) => {
              const areaRiskCount = area.risks.length || 1;

              return area.risks_identified ? (
                area.risks.map((riskArea, riskAreaIndex) => (
                  <tr
                    key={`${riskIndex}-${area.name}-${riskArea.name}`}
                    onMouseEnter={() => setHover({ area: area.name, category: risk.category })}
                    onMouseLeave={() => setHover({ area: '', category: '' })}
                    onClick={() => scrollReadMoreIntoView(area.name)}
                    style={{
                      backgroundColor: `${area.name === hover.area ? theme.palette['hyperlink']['cell'] : ''}`,
                      cursor: 'pointer',
                    }}
                  >
                    {areaIndex === 0 && riskAreaIndex === 0 && (
                      <td
                        rowSpan={getTotalLength(risk.areas)}
                        style={{
                          backgroundColor: `${risk.category === hover.category ? theme.palette['hyperlink']['cell'] : ''}`,
                        }}
                      >
                        {risk.category}
                      </td>
                    )}
                    {riskAreaIndex === 0 && (
                      <td style={{ verticalAlign: 'middle' }} rowSpan={areaRiskCount}>
                        {area.display_name}
                      </td>
                    )}
                    <td style={{ verticalAlign: 'middle' }}>
                      {area.site_risk
                        ? 'No project risk, some risk related to facility or site'
                        : riskArea.descriptive_name || 'None'}
                    </td>
                    <StyledTD
                      sx={{ verticalAlign: 'middle' }}
                      riskLevel={riskArea.risk_level || 'no_risks_identified'}
                    >
                      {capitalizeFirstLetter(
                        riskArea.risk_level === 'insufficient_evidence'
                          ? 'Insufficient evidence'
                          : riskArea.risk_level || 'No risks identified'
                      )}
                    </StyledTD>
                  </tr>
                ))
              ) : (
                <tr
                  key={`${riskIndex}-${area.name}-no-risk`}
                  onMouseEnter={() => setHover({ area: area.name, category: risk.category })}
                  onMouseLeave={() => setHover({ area: '', category: '' })}
                  onClick={() => scrollReadMoreIntoView(area.name)}
                  style={{
                    backgroundColor: `${area.name === hover.area ? theme.palette['hyperlink']['cell'] : ''}`,
                    cursor: 'pointer',
                  }}
                >
                  {areaIndex === 0 && (
                    <td
                      rowSpan={getTotalLength(risk.areas)}
                      style={{
                        backgroundColor: `${risk.category === hover.category ? theme.palette['hyperlink']['cell'] : ''}`,
                      }}
                    >
                      {risk.category}
                    </td>
                  )}
                  <td style={{ verticalAlign: 'middle' }}>{area.display_name}</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {area.site_risk
                      ? 'No project risk, some risk related to facility or site'
                      : 'None'}
                  </td>
                  <StyledTD sx={{ verticalAlign: 'middle' }} riskLevel='no_risks_identified'>
                    No risks identified
                  </StyledTD>
                </tr>
              );
            })}
          </>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default RiskSummaryTable;
