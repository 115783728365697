import { Box, Divider, Stack, Typography, useTheme } from '@mui/joy';

import { getRiskAreaColorCode } from '../../../../../utils/constants/colorCode';
import { capitalizeFirstLetter } from '../../../../../utils/functions/string';
import { screeningIconMap } from '../../../constants';
import { StyledHTML } from '../StyledHTML';

import ImageAndDescription from './ImageAndDescription';

const RiskInfo = ({ data: risk }) => {
  const theme = useTheme();
  return (
    <>
      <Stack gap={1}>
        <Typography level='h3' fontWeight={'xl'} color='primary'>
          {risk.category} risks
        </Typography>
        <Divider orientation='horizontal' sx={{ width: '100%' }} />
      </Stack>
      {risk.areas.map((area) => (
        <Stack gap={1} id={area.name} key={area.name}>
          <Stack direction={'row'} gap={2} alignItems={'center'}>
            {screeningIconMap?.[area.name]?.icon({ width: 45, height: 45 })}
            <Typography level='h4' color='primary'>
              {area.display_name}
            </Typography>
          </Stack>
          <Stack gap={4}>
            {(area.risks.length > 0 ? area.risks : [null]).map((areaRisk) => (
              <Stack gap={2}>
                <Stack gap={1}>
                  <Stack
                    direction={'row'}
                    gap={1}
                    mt={1}
                    flexWrap={'wrap'}
                    fontSize={'xl'}
                    fontWeight={'xl'}
                    color='primary'
                    alignItems={'center'}
                    key={areaRisk?.name || area?.name}
                  >
                    {area.risks_identified && (
                      <>
                        Risk: {areaRisk?.descriptive_name || 'No risks identified'}
                        <Divider
                          sx={{ height: (theme) => theme.spacing(3) }}
                          orientation='vertical'
                        />
                      </>
                    )}
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                      Risk level:
                      <Box
                        component={'span'}
                        sx={{
                          borderRadius: '50%',
                          width: (theme) => theme.spacing(2),
                          height: (theme) => theme.spacing(2),
                          background: (theme) =>
                            getRiskAreaColorCode(theme, true)[
                              areaRisk?.risk_level || 'no_risks_identified'
                            ],
                        }}
                      ></Box>
                      {capitalizeFirstLetter(
                        areaRisk?.risk_level === 'insufficient_evidence'
                          ? 'Insufficient evidence'
                          : areaRisk?.risk_level || 'No risks identified'
                      )}
                    </Stack>
                  </Stack>
                  {/* {area.risks_identified && (
                    <Stack direction={'row'} flexWrap={'wrap'} gap={2} alignItems={'center'}>
                      <Typography
                        fontSize={'lg'}
                        fontWeight={'md'}
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                      >
                        Risk related to: {riskRelatedTo[areaRisk?.risk_related_to] || ''}
                      </Typography>

                      <Divider
                        sx={{ height: (theme) => theme.spacing(3) }}
                        orientation='vertical'
                      />
                      <Typography
                        fontSize={'lg'}
                        fontWeight={'md'}
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                      >
                        Reported mitigation/management measures:
                      </Typography>
                      <Stack direction={'row'} gap={1} alignItems={'center'}>
                        {areaRisk?.mitigation_evidence ? (
                          <FaCheckCircle size={20} color={theme.palette['GHG']['A+']} />
                        ) : (
                          <IoMdCloseCircle size={20} color={theme.palette.text.tertiary} />
                        )}
                        <Typography
                          fontSize={'lg'}
                          fontWeight={'md'}
                          sx={{ color: (theme) => theme.palette.text.secondary }}
                        >
                          {areaRisk?.mitigation_evidence ? 'Yes' : 'No'}
                        </Typography>
                      </Stack>
                    </Stack>
                  )} */}
                  {area.risks_identified && areaRisk?.risk_related_to_lack_of_info && (
                    <Typography
                      fontSize={theme.vars.fontSize.lg}
                      fontWeight={'md'}
                      sx={{ color: (theme) => theme.palette.text.secondary }}
                    >
                      {areaRisk?.risk_related_to_lack_of_info_reason}
                    </Typography>
                  )}
                </Stack>
                <Stack gap={1}>
                  <StyledHTML
                    fontSize={theme.vars.fontSize.md}
                    fontWeight={'md'}
                    color='primary'
                    dangerouslySetInnerHTML={{
                      __html: !area.risks_identified
                        ? area.no_risks_identified_reason
                        : areaRisk?.description,
                    }}
                  />
                  {area.risks_identified && areaRisk?.image_url && (
                    <ImageAndDescription
                      image={areaRisk?.image_url}
                      description={areaRisk?.image_description}
                    />
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}
    </>
  );
};

export default RiskInfo;
